<template>
  <div class="btn-container_foreground" :style="cssVariables">
    <div class="btn-container">
      <div
        v-for="(button, index) in localOptions"
        :key="`label-${index}`"
        :class="{
          [`btn-item-${index} labels`]: true,
          ['checked']: button.isActive,
          ['unchecked']: !button.isActive,
        }"
        @click="handleLabelClick(button)"
      >
        <div class="active-label">
          <div v-if="button.badge" class="btn-badge">
            {{ button.badge }}
          </div>
          <slot name="icon" :props="button">
            <i class="material-icons bottom-bar-icon">{{ button.icon}}</i>
          </slot>
        </div>

        <div class="btn-title">
          <slot name="title" :props="button">
            {{ button.title }}
          </slot>
        </div>

        <div
          v-if="hasChild(button)"
          :class="{
            ['btn-super-parent']: button.isActive,
            ['btn-class-showable']: showable,
          }"
        >
          <div class="btn-child-parent">
            <div
              v-for="(child, idx) in button.childs || []"
              :key="idx"
              class="btn-child"
              :class="child.color"
              @click.stop="handleChildClick(child)"
            >
              <slot name="child-icon" :props="child">
                <i class="material-icons bottom-bar-icon">{{ child.icon}}</i>
                <slot name="child-title" :props="child">
                  {{ child.title }}
                </slot>
              </slot>

              <div v-if="child.badge" class="btn-child-badge">
                {{ child.badge }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-show="hasActiveClass" id="sweep">
        <div id="sweep-right" />
        <div id="sweep-center" />
        <div id="sweep-left" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CurvedBottomNavigation",
  model: {
    prop: "value",
    event: "update",
  },
  props: {
    value: {
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    foregroundColor: {
      type: String,
      default: "#edf1f6",
    },
    backgroundColor: {
      type: String,
      default: "#3949ab",
    },
    iconColor: {
      type: String,
      default: "#ffffff",
    },
    badgeColor: {
      type: String,
      default: "#FBC02D",
    },
    replaceRoute: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    localOptions: [],
    showable: false,
    enableWatch: true,
  }),
  computed: {
    cssVariables() {
      const countChilds = (
        (this.localOptions.find((option) => option.isActive) || {}).childs || []
      ).length;

      const styles = {
        "--color-foreground": this.foregroundColor,
        "--color-background": this.backgroundColor,
        "--color-icon": this.iconColor,
        "--color-badge": this.badgeColor,
        "--width-parent": `${countChilds * 60}px`,
      };

      return styles;
    },

    hasActiveClass() {
      return this.localOptions.some((option) => option.isActive);
    },
  },
  watch: {
    options: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.localOptions = newVal.map((option) => ({
            ...option,
            isActive: this.isActive(option),
          }));
          this.cssLoader();
        }
      },
    },
    value: {
      handler(newVal, oldVal) {
        if (newVal != oldVal && this.enableWatch) {
          const childs = [];
          this.localOptions.forEach((option) => {
            if (option.childs) {
              childs.push(...option.childs);
            }
          });
          // const target = [...this.localOptions, ...childs].find(
          //   (option) => option.id == newVal
          // );
          // if (target) {
          //   this.updateValue(target, this.hasChild(target));
          // }
        }
      },
    },
    // $route: {
    //   immediate: true,
    //   handler(newRoute) {
    //     if (newRoute) {
    //       this.$nextTick(() => {
    //         const childs = [];
    //         this.localOptions.forEach((option) => {
    //           if (this.hasChild(option)) {
    //             childs.push(...option.childs);
    //           }
    //         });
    //         const target = [...this.localOptions, ...childs]
    //           .filter((item) => item.path)
    //           .find((option) => {
    //             if (typeof option.path === "string") {
    //               return option.path === newRoute.path;
    //             } else {
    //               return option.path.name === newRoute.name;
    //             }
    //           });
    //         if (target) {
    //           this.updateValue(target, this.hasChild(target));
    //         }
    //       });
    //     }
    //   },
    // },
  },
  created() {
    this.localOptions = this.options.map((option) => ({
      ...option,
      isActive: this.isActive(option),
    }));
  },
  mounted() {
    this.cssLoader();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    cssLoader() {
      let customStyle = "";
      const containerWidth =
        document.querySelector(".btn-container").offsetWidth ||
        window.innerWidth;

      this.options.forEach((item, index) => {
        if ((item.childs || []).length > 1) {
          if (index === 0 && this.hasChild(item)) {
            customStyle += `
          .btn-item-${index}.checked .btn-class-showable .btn-child-parent {
            transform: translateX(${(item.childs.length * 45) / 2 - 35}px);
            transition: transform 500ms ease 300ms;
          }
          `;
          }

          if (index === this.options.length - 1 && this.hasChild(item)) {
            customStyle += `
          .btn-item-${index}.checked .btn-class-showable .btn-child-parent {
            transform: translateX(-${(item.childs.length * 45) / 2 - 35}px);
            transition: transform 500ms ease 300ms;
          }
          `;
          }
        }

        customStyle += `
        .btn-item-${index} {
          padding: 10px;
          transition: transform 100ms ease;
          width : ${containerWidth / this.options.length}px !important;
          display: flex;
          justify-content :center;
          align-items : center;
          position: relative;
          z-index: 10;
        }

        .btn-item-${index}.checked ~ #sweep {
          transform: translateX(${
            (index * containerWidth) / this.options.length +
            containerWidth / this.options.length / 4
          }px);
          transition: transform 500ms ease;
        }
        `;

        if (this.hasChild(item)) {
          item.childs.forEach((child, idx) => {
            customStyle += `
            .btn-item-${index}.checked .btn-class-showable .btn-child:nth-child(${
              idx + 1
            }) {
              transform: translateX(${
                (0.5 + idx) * 45 - (item.childs.length * 45) / 2
              }px);
              transition: transform 500ms ease 300ms;
            }
          `;
          });
        }
      });

      document.getElementById("sweep").style.left = `
      ${containerWidth / this.options.length / 4 - 135 / 2}px`;

      const head = document.getElementsByTagName("head")[0];
      const style = document.createElement("style");
      style.id = "bottom-navigation-style";

      if (style.styleSheet) {
        style.styleSheet.cssText = customStyle;
      } else {
        style.appendChild(document.createTextNode(customStyle));
      }

      head.appendChild(style);
    },
    handleLabelClick(button) {
      if (!this.showable || button.isActive) {
        this.toggleClass();
      }


      this.updateValue(button, this.hasChild(button));
      // if (typeof button.path === "string") {
      //   this.$comm.movePage(this.$router, typeof button.path)
      // }
    },
    handleChildClick(button) {
      this.updateValue(button);
      this.toggleClass();
    },
    updateValue(button, prevent = false) {
      // 버튼에 따라 아이콘 이동여부 결정
      if (button.move) {
        this.localOptions.forEach(
          (option) => (option.isActive = this.isActive(option, button.id))
        );
      }

      if (!prevent) {
        this.$emit("update", button.id);
        this.enableWatch = false;
        setTimeout(() => {
          this.enableWatch = true;
        }, 0);
        if (button.types == 'SERACH') { // 검색조건
          this.$emit('callbackSearchAction');
        } else if (button.types == 'MENU') { // 메뉴오픈
          this.$emit('callbackMenuAction');
        } else if (button.types == 'SWP1') { // 일반작업허가서
          this.$emit('callbackSwpAction', { permitTypeCd: 'SPT0000001', });
        } else if (button.types == 'SWP2') { // 화기작업허가서
          this.$emit('callbackSwpAction', { permitTypeCd: 'SPT0000005', });
        } else if (button.types == 'MENU000398' 
        || button.types == 'MENU000399'
        || button.types == 'MENU000478'
        || button.types == 'MENU000407'
        || button.types == 'MENU100220'
        || button.types == 'MENU100176'
        || button.types == 'MENU000400'
        || button.types == 'MENU100230'
        || button.types == 'MENU100353'
        || button.types == 'MENU100245') { 
          // 순회점검 / 개선관리 / 안전관찰 / TBM / 작업 전 안전점검 / 협력업체 개선관리
          // 소방점검 / 아차사고
          this.$emit('callbackAction', button.types);
        } else {
          if (this.$route.fullPath !== button.path) {
            if (button.path && Object.keys(button.path).length) {
              if (this.replaceRoute) {
                this.$router.replace(button.path).catch(() => {});
              } else {
                this.$router.push(button.path);
              }
            }
          }
        }

      }
    },
    toggleClass() {
      this.showable = !this.showable;
    },
    isActive(button, value = this.value) {
      return (
        button.id == value ||
        (button.childs || []).find((child) => child.id == value)
      );
    },
    onResize() {
      this.$nextTick(() => {
        const styleElement = document.getElementById("bottom-navigation-style");
        styleElement && styleElement.remove();
      });

      this.cssLoader();
    },
    hasChild(button) {
      return (button.childs || []).length;
    },
  },
};
</script>

<style scoped>
.btn-super-parent {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 55px;
  width: var(--width-parent);
  height: 60px;
  z-index: -1;
}

.btn-super-parent.btn-class-showable{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 55px;
  width: var(--width-parent);
  height: 60px;
  z-index: -1;
}

input {
  display: none;
}

.btn-container_foreground {
  position: fixed;
  direction: ltr;
  display: flex;
  align-items: flex-end;
  bottom: 0;
  width: 100%;
  z-index: 2147483647;
  height: 60px;
  background: var(--color-foreground);
}

.btn-container {
  direction: ltr;
  display: flex;
  justify-content: space-around;
  background-color: var(--color-background);
  width: 100%;
  height: 55px;
}

.active-label {
  width: 35px;
  height: 35px;
  border-radius: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease;
  position: absolute;
  top: 10px;
  background: var(--color-background) !important;
  color: var(--color-icon);
}

.btn-title {
  position: absolute;
  color: var(--color-icon);
  font-size: 10px;
  line-height: 1.15 !important;
}

.btn-badge {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 25px;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  background: var(--color-badge);
}

.checked {
  background: transparent !important;
}

.checked .active-label {
  transform: translateY(-10px);
}

.checked .btn-title {
  animation: fadein 200ms;
  position: absolute;
  top: 40px;
}

.unchecked .active-label {
  background: transparent;
}

.unchecked .btn-title {
  visibility: hidden;
}

#sweep {
  height: 100%;
  width: 135px;
  display: flex;
  position: absolute;
  left: 0;
  top: 5px;
}

#sweep-center {
  height: 38px;
  display: flex;
  flex: 1;
  background: var(--color-foreground);
  border-radius: 0 0 45% 45%;
}

#sweep-left {
  height: 33px;
  width: 45px;
  overflow: hidden;
  position: relative;
  right: 2px;
}

#sweep-left:before {
  content: "";
  display: block;
  width: 220%;
  height: 200%;
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  box-shadow: -40px -40px 0 0 var(--color-foreground);
}

#sweep-right {
  height: 33px;
  width: 45px;
  overflow: hidden;
  position: relative;
  left: 2px;
}

#sweep-right:before {
  content: "";
  display: block;
  width: 220%;
  height: 200%;
  position: absolute;
  border-radius: 50%;
  top: 0;
  right: 0;
  box-shadow: 40px -40px 0 0 var(--color-foreground);
}

@media screen and (min-width: 576px) {
  .labels {
    cursor: pointer;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

/* child */

.btn-child-badge {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -4px;
  left: 20px;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  background: var(--color-badge);
  opacity: 0;
}

.btn-child-parent {
  position: absolute;
  bottom: -55px;
  width: 35px;
  height: 35px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.btn-child {
  position: absolute;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  background: var(--color-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-icon);
}

.btn-child-title {
  font-size: 10px;
  opacity: 0;
  position: absolute;
  top: 37px;
  line-height: 1.15 !important;
}

.unchecked .btn-child-parent {
  background: transparent;
}

.checked .btn-class-showable .btn-child-parent {
  animation: child-background 500ms ease-in-out forwards;
}

.checked .btn-class-showable .btn-child-title {
  animation: child-title 500ms ease-in-out forwards;
}

.checked .btn-class-showable .btn-child-badge {
  animation: child-title 500ms ease-in-out forwards;
}

.checked .btn-class-showable .btn-child-parent .btn-child {
  height: 60px;
  width: 36px;
}

@keyframes child-title {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes child-background {
  0% {
    bottom: -30px;
    background: transparent;
  }

  25% {
    bottom: 20px;
    width: 35px;
    height: 35px;
  }

  40% {
    bottom: 20px;
    width: 35px;
    height: 40px;
  }

  100% {
    bottom: 20px;
    width: 100%;
    height: 40px;
  }
}
</style>
