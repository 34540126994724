<!--
  목적 : 사업장 select box
  작성자 : kdh
  Detail :
  *
  examples:
  *
  -->
<template>
  <div class="fix-height">
    <q-input
      readonly :dense="dense"
      :label="convertLabel"
      ref="customDept"
      class="customDept q-field--with-bottom"
      stack-label
      :placeholder="convertPlaceholder"
      :disable="disabled || !editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="textDeptName"
      >
      <template v-slot:label>
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{convertLabel}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>

      <template v-slot:append>
        <slot name="custom-btn-icon"></slot>
        <q-icon v-if="editable&&searchFlag" name="search" @click="click" class="cursor-pointer"/>
        <q-icon v-if="editable&&closeFlag" name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-input>

    <q-dialog
      ref="deptDialog"
      persistent
      position="bottom"
      v-model="isOpen">
      <q-card class="dept-card">
        <q-card-section class="row items-center q-pb-none px-2 py-1 bg-orange-7 text-white">
          <div class="text-h6">{{$language('부서')}}</div>
          <q-space></q-space>
          <q-btn icon="done" flat round dense @click="selectDept"></q-btn>
          <q-btn icon="close" flat round dense v-close-popup></q-btn>
        </q-card-section>
        <q-item>
          <q-item-section>
            <q-item-label caption>
              <q-chip outline square color="primary" text-color="white">
                <!-- <q-chip color="primary" text-color="white">
                  선택 부서
                </q-chip> -->
                <!-- <q-avatar color="primary" text-color="white">선택 부서</q-avatar> -->
                {{deptName}}
              </q-chip>
              <q-input ref="filter" dense v-model="filter" label="Filter">
                <template v-slot:append>
                  <q-icon v-if="filter !== ''" name="clear" class="cursor-pointer" @click="resetFilter" />
                </template>
              </q-input>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-separator></q-separator>

        <q-card-section class="dept-content p-2">
          <q-tree
            ref="customDept"
            :nodes="deptTree"
            node-key="deptCd"
            label-key="deptName"
            children-key="children"
            :no-nodes-label="$language('부서 정보가 없습니다.')"
            :no-results-label="$language('필터링 된 데이터가 없습니다.')"
            :filter="filter"
            :default-expand-all="defaultExpandAll"
            selected-color="primary"
            :selected.sync="selected"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import mixinCommon from './js/mixin-common'
import arrayToTree from 'array-to-tree';
export default {
  name: 'c-dept',
  mixins: [mixinCommon],
  props: {
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'edit',
    },
    editable: {
      type: Boolean,
      default: true,
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    // 본인부서를 초기값으로 설정할지 여부
    isFirstValue: {
      type: Boolean,
      default: true,
    },
    // 라벨 텍스트
    label: {
      type: String,
      default: '부서', // 부서
    },
    plantCd: {
      type: String,
    },
    // 사용 여부
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    defaultExpandAll: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: String,
      default: 'single',
    },
    searchFlag: {
      type: Boolean,
      default: true,
    },
    closeFlag: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      deptCd: '',
      deptName: '부서를 선택하세요',
      textDeptName: '',
      placeholder: '선택하세요',
      roleAccess: false,
      /** kdh */
      isOpen: false,
      filter: '',
      selected: null,
    };
  },
  computed: {
    depts() {
      let list = [];
      if (this.$store.getters.user.plantCds !== null && this.$store.getters.user.plantCds !== '') {
        let plantCds = this.$store.getters.user.plantCds.split(',');
        this.$_.forEach(this.$store.state.systemInfo.depts, _item => {
          let index = plantCds.findIndex(a => a === _item.plantCd);
          if (index > -1) {
            list.push(_item);
          }
        })
      } else {
        list = this.$_.filter(this.$store.state.systemInfo.depts, { plantCd: this.$store.getters.user.plantCd });
      }
      if (this.plantCd) {
        list = this.$_.filter(list, { plantCd: this.plantCd });
      }
      if (this.editable && !this.disabled) {
        // 사용여부가 Y인 것만 리스트에 표현한다.
        // default : 사용여부 상관없이 전체
        list = this.$_.filter(list, { useFlag: 'Y' });
      }
      return list;
    },
    deptTree() {
      let val = this.convertTree(this.depts);
      return val ? val : [];
    },
  },
  watch: {
    // TODO : 부모의 v-model 변경을 감시(예를 들면, db로부터 데이터를 조회 한 후 값을 바인딩 할 경우)
    value() {
      this.checkDefault();
    },
    plantCd() {
      this.checkDefault();
    },
    selected() {
      let data = this.$refs['customDept'].getNodeByKey(this.selected);

      this.deptCd = this.selected;
      this.deptName = data.deptName;
    },
    searchFlag() {
      if (this.searchFlag === false) {
        this.$refs['customDept'].$el.getElementsByClassName('q-field__native')[0].removeEventListener('click', this.click);
      }
    },
    disabled() {
      if (this.disabled) {
        this.$refs['customDept'].$el.getElementsByClassName('q-field__native')[0].removeEventListener('click', this.click);
      } else {
        this.$refs['customDept'].$el.getElementsByClassName('q-field__native')[0].removeEventListener('click', this.click);
        this.$refs['customDept'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.click);
      }
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
    if (!this.disabled) {
      this.$refs['customDept'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.click);
    }
  },
  beforeDestroy() {
    if (!this.disabled) {
      this.$refs['customDept'].$el.getElementsByClassName('q-field__native')[0].removeEventListener('click', this.click);
    }
  },
  methods: {
    init() {
      this.placeholder = this.type === 'search' ? 
        this.$language('전체') : // 전체
        this.$language('선택'); // 선택
      // if (this.value) {
      //   this.roleAccess = !((this.$store.getters.deptAuthGrp && this.$store.getters.deptAuthGrp.deptAccessYn === 'Y') || this.showAllDept)
      // } else {
      //   this.roleAccess = !((this.$store.getters.deptAuthGrp && this.$store.getters.deptAuthGrp.deptAccessYn === 'Y') || this.showAllDept);
      // }
      if (this.isFirstValue) {
        if (!this.value && (this.plantCd ? this.$store.getters.user.plantCd === this.plantCd : true)) {
          this.$emit('input', this.$store.getters.user.deptCd);
          this.$emit('setDeptName', this.$store.getters.user.deptName);
        } else {
          this.checkDefault();
        }
      }
      if (this.searchFlag === false) {
        this.$refs['customDept'].$el.getElementsByClassName('q-field__native')[0].removeEventListener('click', this.click);
      }
    },
    checkDefault() {
      if (this.isFirstValue) {
        let data = null;
        if (this.value) {
          data = this.$_.find(this.depts, { deptCd: this.value });
          if (!data) {
            this.deptCd = null;
            this.deptName = null;
          } else {
            this.deptCd = data.deptCd;
            this.deptName = data.deptName;
          }
        } else {
          this.deptCd = null;
          this.deptName = null;
        }
      } else {
        let data = null;
        if (this.value) {
          data = this.$_.find(this.depts, { deptCd: this.value });
          if (!data) {
            this.deptCd = null;
            this.deptName = null;
          } else {
            this.deptCd = data.deptCd;
            this.deptName = data.deptName;
          }
        } else {
          this.deptCd = null;
          this.deptName = null;
        }
      }

      this.textDeptName = this.deptName;
      this.$emit('input', this.deptCd);
      this.$emit('setDeptName', this.deptName);
      this.$emit('datachange', this.deptCd);
    },
    /**
     * 메뉴 트리용 기초 정보를 Tree 메뉴로 변환
     */
    convertTree(_treeBases) {
      if (!_treeBases || _treeBases.length <= 0) return null;
      var tree = arrayToTree(_treeBases, {
        parentProperty: 'upDeptCd',
        customID: 'deptCd',
      });
      return tree;
    },
    click() {
      if (this.editable && !this.disabled) {
        this.isOpen = true;
      }
    },
    resetFilter () {
      this.filter = ''
      this.$refs.filter.focus()
    },
    reset() {
      this.textDeptName = '';
      this.deptCd = '';
      this.deptName = '';
      this.$emit('input', this.deptCd);
      this.$emit('setDeptName', this.deptName);
      this.$emit('datachange', this.deptCd);
    },
    selectDept() {
      if (this.deptName !== '부서를 선택하세요') {
        this.textDeptName = this.deptName;
        this.$emit('input', this.deptCd);
        this.$emit('setDeptName', this.deptName);
        this.$emit('datachange', this.deptCd);
      }
      this.$refs['deptDialog'].hide();
    }
  },
};
</script>
<!-- <style lang="sass">
.dept-card
  width: 100%
  max-width: 450px !important
  height: 100%
  max-height: 500px !important
  overflow-y: hidden !important
.dept-content
  height: 100%
  max-height: 370px !important
  overflow-y: auto
.dept-card
  .bg-dialogheader
    height: 40px
    .q-btn
      .q-btn__wrapper
        padding: 0px !important
  .text-h6
    font-size: 1.2em
    line-height: 1.2em
    font-weight: 700
.customDept
  .q-field__native
    padding-left:3px
    font-size: 1.05em !important
  .q-field__label
    margin-left: 5px
  .q-field__native::placeholder
    font-size: 1em !important
  .material-icons
    font-size: 0.8rem
  .deptCloseIcon
    display: none
.customDept:hover
  .deptCloseIcon
    display: block
.customDept.q-field--dense .q-field__control, .customDept.q-field--dense .q-field__marginal
  height: 38px !important
  min-height: 38px !important

.customDept.q-field--filled .q-field__control
  border-bottom: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px

.customDept.q-field--filled.q-field--readonly .q-field__control:before
  opacity: 0
</style> -->