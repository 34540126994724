/* eslint-disable */
import router from './router';
import store from './store';
import {
  getToken,
  haveRoute,
} from '@/utils/auth'; // getToken from cookie
import transactionConfig from '@/js/transactionConfig';
import $http from '@/js/http';
import _ from 'lodash'

/**
 * 인증처리가 되지 않더라도 보낼 수 있는 uri
 */
const whiteList = ['/login', '/404', '/sessionTimeOut', ];

router.beforeEach((to, from, next) => {
  /** 메뉴 이동시 전체화면 로딩 처리 */
  store.dispatch('setLoading', true);

  /**
   * vue-router에 저장 되지 않은 메뉴 정보일 경우 404 페이지로 redirect
   */
  if (to.path === '/404') {
    next();
  } else {
    if (store.getters.addRouters && store.getters.addRouters.length > 0) { //  && !to.name
      if (!haveRoute(store.getters.addRouters, to.path)) {
        return next({
          path: '/404'
        });
      }
    }
    /**
     * 메뉴 이동전에 uri 정보를 loaclStorage에 저장
     * 
     * 인증토큰이 만료 되었을 시에 로그인창을 띄우게 되며 
     * 로그인 처리가 끝나고 나면 localStorage에 저장한 uri 정보를 바탕으로 사용자가 가고자한 페이지로 이동하게 함
     */
    window.localStorage.setItem('checkToPath', to.path);

    /**
     * 인증토큰 여부 파악
     */
    let isToken = getToken();
    if (isToken && isToken !== 'undefined') {
      /**
       * 인증토큰이 있음에도 로그인페이지로 이동하고자 할 경우
       * 메인페이지로 이동시킴
       */
      if (to.path === '/login') {
        next({
          path: '/'
        });
      } else {
        /**
         * 메뉴 이동 로그
         * 
         * ## to.name :: menu id
         */
        if (to.name 
          && store.getters.user.userId 
          && to.meta.menulog != 'N'
          && process.env.NODE_ENV !== 'test') {
          $http.url = transactionConfig.sys.log.menulog.insert.url;
          $http.type = 'POST';
          $http.param = {
            sysMenuId: to.name,
            url: to.path,
            userId: store.getters.user.userId,
            userName: store.getters.user.userName,
            deviceType: 'M',
          };
          $http.request(() => {
          },);
        }

        /**
         * 로그인자가 권한을 가지고 있는지 여부 파악
         * 
         * api server에서 권한을 가지고 있지 않은 사람의 경우에는 
         * default 권한을 내려줌으로 권한이 없을 수 없음
         * 
         * 다만 처음 로그인을 한 경우에는 권한을 아직 가지고 오지 않았음으로
         * 사용자 정보, 다국어 정보, 접근가능한 메뉴
         *   ※ getters에 담기게 되는 경우 refresh나 창이 새로 떠지는 경우
         *      getters 정보가 사라질 수 있음으로 메뉴 이동시에 계속적으로 체크가 필요함
         *   ※ 사용자 정보와 접근가능한 메뉴는 한번에 가지고 오도록 처리
         * 
         * ## 사용자 정보
         *  1. 권한 (사용자 또는 사용자의 부서에 적용된 권한들을 가지고 옴)
         *  2. 사용자 info << id, name, 부서명, 부서코드, 사업장코드, 사업장명
         *  3. 사업장/부서 접근권한
         * 
         * ## 다국어 정보
         *  1. 라벨
         *  2. 메시지
         * 
         * ## 접근가능한 메뉴
         *  1. 표시할 메뉴정보
         *  2. 사용자가 가지는 권한에 따라 접근가능한 메뉴 정보
         *  - 위의 두 정보를 바탕으로 내가 접근가능한 메뉴를 vue-router에 저장
         */
        if (!store.getters.user.empNo) {
          // // local storage에 있는 정보를 다 지운다
          // localStorage.clear();

          store
            .dispatch('GetUserInfo')
            .then(result => {
              // user_info 조회
              /**
               * 권한에 따른 메뉴 처리가 될 시에 사용
               * 
               * 현재는 해당 로직이 없음으로 사용하지 않음
               */
              store.dispatch('GenerateRoutes', {
                empNo: store.getters.user.empNo
              }).then(() => {
                let forEachData = _.filter(store.getters.addRouters, (item) => {
                  return _.findIndex(router.getRoutes(), { name: item.name }) === -1
                })
                _.forEach(forEachData, menuInfo => {
                  router.addRoute(menuInfo);
                })
                next({
                  ...to,
                  replace: true
                }); 
                store
                .dispatch('SetLangJson')
                .then(() => {
                })
                .catch(err => {
                  // console.log(err)
                  store.dispatch('FedLogOut').then(() => {
                    next('/login');
                  });
                });;
              });
            })
            .catch(err => {
              store.dispatch('FedLogOut').then(() => {
                // Message.error(err || 'Verification failed, please login again');
                next('/login');
              });
            });
            store
              .dispatch('GetUsers')
              .then(() => { });
              // .then(data => { console.log('# GetUsers: ', data) });
            store
              .dispatch('GetDepts')
              .then(() => { });
              // .then(data => { console.log('# GetDepts: ', data) });
            store
              .dispatch('GetVendors')
              .then(() => { });
              // .then(data => { console.log('# GetVendors: ', data) });
            store
              .dispatch('GetVendorUsers')
              .then(() => { });
              // .then(data => { console.log('# GetVendors: ', data) });
        } else {
          store
            .dispatch('ValidCheckToken')
            .then(result => {
              next();
            })
            .catch(err => {
              // refresh_token을 재발급 처리시도
              store
                .dispatch('ValidCheckRefreshToken')
                .then(() => {
                  next();
                })
                .catch(err => {
                  // 로그인 창띄우고 처리되면 next()처리를 해야 한다.
                  // $('#goLogin').click();
                  next(`/login?redirect=${to.path}`);
                });
            });
        }
      }
    } else {
      // // local storage에 있는 정보를 다 지운다
      // localStorage.clear();
      /* has no token*/
      if (whiteList.indexOf(to.path) !== -1) {
        next();
      } else {
        let path = to.fullPath || to.path
        next(`/login?redirect=${path}`);
      }
    }
  }
});

router.afterEach(() => {
  store.dispatch('setLoading', false);
});
