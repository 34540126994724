<!--
목적 : 확장검색 기능을 지원하는 컴포넌트
Detail :
 *
examples:
 *
-->
<template>
  <div v-if="isUploaderSetting">
    <q-uploader
      flat bordered with-credentials
      ref="table-upload"
      class="table-upload"
      capture="environment"
      field-name="file" 
      :url="url"
      :headers="headers"
      method="POST"
      :label="convertLabel"
      :form-fields="formFields"
      :auto-upload="true"
      :hide-upload-btn="true"
      :multiple="setting.multipleSelFlag === 'Y'"
      :accept="setting.acceptExt"
      :max-file-size="setting.limitSize * 1000000"
      :max-total-size="setting.limitSize * 1000000 * setting.limitCnt"
      :max-files="maxFiles"
      :no-thumbnails="setting.previewFlag === 'Y'"
      :disable="!editable"
      :style="maxheight"
      @finish ="finish"
      @uploaded="uploaded"
      @failed="failed"
      @rejected="rejected"
    >
        <!-- :filter="filter" -->
      <template v-slot:header="scope">
        <div class="row no-wrap items-center q-pa-none q-gutter-xs">
          <q-spinner v-if="scope.isUploading" class="q-uploader__spinner" />
          <div class="col no-margin">
            <div class="q-uploader__subtitle inline-block">{{ scope.uploadSizeLabel }} / {{ scope.uploadProgressLabel }}</div>
          </div>
          <q-btn 
            v-if="editable&&maxFiles>0" 
            class="no-margin"
            type="a" 
            icon="add" 
            size="sm"
            round dense flat>
            <q-uploader-add-trigger />
            <!-- <q-tooltip>파일 선택하기</q-tooltip> -->
          </q-btn>
          <q-btn
            v-if="haveImage()"  
            class="no-margin"
            icon="visibility"
            round dense flat
            size="sm"
            @click="allImagePreView">
            <!-- <q-tooltip>이미지 파일 미리보기</q-tooltip> -->
          </q-btn>
          <q-btn
            v-if="fileList&&fileList.length > 0"  
            class="no-margin"
            icon="delete_outline"
            round dense flat
            size="sm"
            @click="removeAllFiles">
            <!-- <q-tooltip>파일 전체 삭제</q-tooltip> -->
          </q-btn>
        </div>
      </template>
      <template v-slot:list="scope">
        <template v-if="scope.files && scope.files.length > 0">
          <q-list separator>
            <q-item v-for="file in scope.files" :key="file.name">
              <q-item-section>
                <q-item-label class="full-width ellipsis">
                  {{ file.name }}
                </q-item-label>
                <q-item-label caption>
                  Status: {{ file.__status }}
                </q-item-label>
                <q-item-label caption>
                  {{ file.__sizeLabel }} / {{ file.__progressLabel }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </template>
        <template v-else>
          <div class="empty_dscription">
            <q-uploader-add-trigger />
          </div>
        </template>
      </template>
    </q-uploader>
    <q-list v-if="fileList&&fileList.length > 0" separator bordered class="uploaded-file-list">
      <q-item v-for="(file, idx) in fileList" :key="idx">
        <q-item-section>    
          <q-item-label class="table-file-origin-name" >
            <span class="text-blue cursor-pointer table-ellipsis" @click.prevent="fileDown(file)">
              {{ file.oriFileNm }}
              <!-- <q-tooltip anchor="bottom left" self="top left">
                {{ file.oriFileNm }}
              </q-tooltip> -->
            </span>
          </q-item-label>
        </q-item-section>
        <q-item-section top side>
          <q-btn
            v-if="editable"
            class="gt-xs"
            size="12px"
            flat
            dense
            round
            icon="delete"
            @click.prevent="removeFile(file)"
          >
            <!-- <q-tooltip>파일 삭제</q-tooltip> -->
          </q-btn>
        </q-item-section>
      </q-item>
    </q-list>
    <!-- 이미지 미리보기 -->
    <q-dialog v-if="preview&&(preview.src || (preview.fileList && preview.fileList.length > 0))" 
      v-model="preview.isShow">
      <q-card class="preview-image-card">
        <q-btn color="white" text-color="primary" @click="preview.isShow = false" round dense icon="close" class="preview-close-btn">
          <!-- 창닫기 -->
          <!-- <q-tooltip class="bg-white text-primary">창닫기</q-tooltip> -->
        </q-btn>
        <q-carousel
          v-if="preview.fileList && preview.fileList.length > 0"
          swipeable animated thumbnails infinite
          v-model="preview.slide"
          @transition="transitionImage"
        >
          <q-carousel-slide
            v-for="(file, idx) in preview.fileList"
            :key="idx"
            :name="file.sysAttachFileId" 
            :img-src="file.src"></q-carousel-slide>
        </q-carousel>
        <q-img v-else :src="preview.src"></q-img>
        <q-card-section>
          <q-btn
            fab
            color="primary"
            icon="save_alt"
            class="absolute"
            style="top: 0; right: 12px; transform: translateY(-50%);"
            @click.prevent="fileDown(preview)"
          ></q-btn>
          <div class="row no-wrap items-center">
            <div class="col text-h6">
              {{preview.oriFileNm}}
            </div>
            <div class="col-auto text-grey text-caption q-pt-md row no-wrap items-center">
              {{ getFileSizeTextByRound(preview.fileSize) }}
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import backendConfig from '@/js/backendConfig.js';
import { getAccessToken } from '@/utils/auth';
import mixinCommon from './js/mixin-common'
export default {
  /* attributes: name, components, props, data */
  name: 'c-upload-column',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '첨부파일',
    },
    maxheight: {
      type: String,
      default: 'max-height:200px;',
    },
    col: {
      type: Object,
      default: function() {
        return {
          taskClassCd: '',
          taskKey: '',
        }
      }
    },
    row: {
      type: Object,
      default: function() {
        return { }
      }
    },
    uploaderSetting: {
      type: Object,
      default: function() {
        return { 
          multipleSelFlag: 'N',
          previewFlag: 'N',
          acceptExt: '*',
          limitSize: 1048576,
          limitCnt: 5,
          noThumbnails: false,
          explainFlag: 'N'
        }
      }
    },
    colUploaderSetting: {
      type: Object,
      default: function() {
        return { 
          multipleSelFlag: 'N',
          previewFlag: 'N',
          acceptExt: '*',
          limitSize: 1048576,
          limitCnt: 5,
          noThumbnails: false,
          explainFlag: 'N'
        }
      }
    },
    imageRestriction: {
      type: Object,
      default: function() {
        return { 
          width: 0,
          height: 0,
        }
      }
    },
  },
  data() {
    return {
      preview: {
        slide: 1,
        isShow: false,
        sysAttachFileId: '',
        src: '',
        oriFileNm: '',
        fileSize: 0,
        description: '',
        fileExt: '',
        fileList: [],
      },
      isUploaderSetting: false,
      fileList: [],
      uploderSettingUrl: '',
      uploadedFilesUrl: '',
      tempTaskKeyUpdateUrl: '',
      allDeleteUrl: '',
      deleteUrl: '',
      previewUrl: '',
      fileDownUrl: '',
    };
  },
  computed: {
    url() {
      return backendConfig.getUrl(transactionConfig.com.upload.uploading.url);
    },
    headers() {
      return [
        { name: 'X-Authorization', value: getAccessToken(), }
      ];
    },
    setting() {
      return this.colUploaderSetting && this.colUploaderSetting.limitCnt > 0 ? this.colUploaderSetting : this.uploaderSetting
    },
    attachInfo() {
      let taskKey = '';
      if (this.col.keyText && this.row[this.col.keyText]) {
        taskKey = this.row[this.col.keyText]
      } else {
        /**
         * 신규인 경우 taskKey가 없을 수 있음
         * 해당의 경우 api-server에서 유니크한 id를 생성하여 저장 후 반환한다.
         */
        taskKey = this.col.taskClassCd + '_' + uid();
        this.$set(this.row, this.col.keyText, taskKey)
      }
      return {
        taskClassCd: this.col.taskClassCd,
        taskKey: taskKey
      }
    },
    formFields() {
      let data = [
        {
          name: 'regUserId',
          value: this.$store.getters.user.userId,
        },
        {
          name: 'chgUserId',
          value: this.$store.getters.user.userId,
        }
      ];
      if (this.attachInfo) {
        data.push({
          name: 'taskClassCd',
          value: this.attachInfo.taskClassCd,
        })
        data.push({
          name: 'taskKey',
          value: this.attachInfo.taskKey,
        })
      }
      return data;
    },
    maxFiles() {
      let fileCnt = this.fileList ? this.fileList.length : 0;
      return this.setting.limitCnt - fileCnt
    }
  },
  watch: {
    'attachInfo.taskClassCd'() {
      this.setUploderSetting();
      this.getUploadedFiles();
    },
    'attachInfo.taskKey'() {
      this.getUploadedFiles();
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {},
  destroyed() {},
  updated() {
  },
  /* methods */
  methods: {
    init() {
      // url setting
      this.uploderSettingUrl = selectConfig.sys.attach.get.url;
      this.uploadedFilesUrl = selectConfig.com.upload.fileList.url
      this.tempTaskKeyUpdateUrl = transactionConfig.com.upload.save.url
      this.allDeleteUrl = transactionConfig.com.upload.allDelete.url
      this.deleteUrl = transactionConfig.com.upload.delete.url
      this.previewUrl = selectConfig.com.upload.preview.url
      this.fileDownUrl = selectConfig.com.upload.fileDown.url
      
      this.setUploderSetting();
      this.getUploadedFiles();
    },
    getFileSizeTextByRound(_fileByteSize) {
      return this.$comm.bytesToSize(_fileByteSize);
    },
    finish() {
      this.$refs['table-upload'].removeUploadedFiles();
      this.$refs['table-upload'].reset();

      this.$emit('upload-change', 'F');
      this.getUploadedFiles();
    },
    uploaded(info) { },
    failed(info) {
      let message = '[';
      if (info && info.files && info.files.length > 0) {
        let idx = 0;
        this.$_.forEach(info.files, file => {
          message += '"' + file.name + (idx !== (info.files.length - 1) ? '", ' : '"] ')
          idx++;
        });
        message += this.$language('업로드에 실패하였습니다.')
      }
      this.$q.notify({
        color: 'negative',
        html: true,
        message: message,
        multiLine: true,
        timeout: 5000
      })
    },
    rejected(info) {
      if (!info || info.length === 0) {
        return;
      }
      let message = '';
      this.$_.forEach(info, reject => {
        // accept, max-file-size, max-total-size, filter, etc
        switch(reject.failedPropValidation) {
          case 'max-file-size': // 파일용량 초과
          case 'max-total-size': // 파일 전체 용량 초과
            message += this.$language('첨부하신 파일의 용량이 지정된 용량보다 큽니다. 파일 용량 : ') + '(' +  this.getFileSizeTextByRound(reject.file.size) + ')';
            break;
          case 'max-files': // 업로드 갯수 초과
            message = this.$language('첨부하신 파일의 지정된 업로드 갯수를 초과하여 업로드 되지 않았습니다.') + '(' +  this.uploaderSetting.limitCnt + ')';
            break;
          case 'accept': // 확장자 맞지않음
            message = this.$language('첨부하신 파일의 확장자가 올바르지 않습니다.')+ '(' +  this.uploaderSetting.acceptExt + ')';
            break;
          case 'filter': // filter 걸린경우
          // 해당 기능 사용하지 않음으로 다국어 처리하지 않음
            message = '첨부하신 이미지 "' + reject.file.name + '"의 사이즈가 올바르지 않습니다. (사이즈 : ' + this.imageRestriction.width + ' X ' + this.imageRestriction.height + ')';
            break;
          default:
            break;
        }
      })
      this.$q.notify({
        color: 'negative',
        html: true,
        message: message,
        multiLine: true,
        timeout: 5000
      })
    },
    setUploderSetting() {
      if (!this.attachInfo.taskClassCd) return
      if (this.colUploaderSetting && this.colUploaderSetting.limitCnt > 0) {
        this.isUploaderSetting = true;
      } else {
        this.$http.url = this.$format(this.uploderSettingUrl, this.attachInfo.taskClassCd);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if (_result.data) {
            this.isUploaderSetting = true;
            this.$_.extend(this.uploaderSetting, _result.data)
          }
        },);
      }
    },
    getUploadedFiles() {
      if (!this.attachInfo.taskClassCd || !this.attachInfo.taskKey) return
      this.$http.url = this.uploadedFilesUrl;
      this.$http.type = 'GET';
      this.$http.param = this.attachInfo;
      this.$http.request((_result) => {
        if (_result.data) {
          // 신규로 등록됨에 따라 임시로 만든 taskKey를 반환처리
          this.fileList = _result.data;
          if (this.fileList && this.fileList.length > 0) {
            this.$_.forEach(this.fileList, item => {
              let encstr = item.previewImage;
              let url = window.URL || window.webkitURL;
              let blob = this.$comm.base64ToBlob(encstr, 'image/jpeg');
              let imgUrl = url.createObjectURL(blob);
              item.imgUrl = imgUrl;
            })
          }

          // 파일 정보들을 테이블에 전달하기 위한 용도
          this.$set(this.row, 'fileList', this.fileList)
        } else {
          this.$set(this.$data, 'fileList', [])
          this.$set(this.row, 'fileList', [])
        }
      },);
    },
    fileDown(file) {
      let thisVue = this;
      let accept = this.$comm.getAccept(file.fileExt);
      this.$http.url = this.fileDownUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        sysAttachFileId: file.sysAttachFileId,
      };
      this.$http.request(
        _result => {
          var url = window.URL || window.webkitURL;
          let link = document.createElement('a');
          var blob = thisVue.$comm.base64ToBlob(_result.data, accept);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, file.oriFileNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = file.oriFileNm;
            link.click();
          }
        },
      );
    },
    haveImage() {
      if (this.fileList && this.fileList.length > 0) {
        let flag = false;
        this.$_.forEach(this.fileList, file => {
          if (file.contentType&&file.contentType.indexOf('image') > -1) {
            flag = true;
            return false;
          }
        })
        return flag;
      } else {
        return false;
      }
    },
    setPreview(file, type) {
      if (!file) return;
      // 이미지인  경우
      if (file.sysAttachFileId) {
        if (type === 'single') {
          this.preview.fileList = [];
        }
        this.$http.url = this.previewUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          sysAttachFileId: file.sysAttachFileId,
        };
        this.$http.request(
          _result => {
            this.preview.isShow = true;
            this.preview.sysAttachFileId = file.sysAttachFileId;
            if (type === 'single' ) {
              this.preview.src = 'data:' + file.contentType + ';base64,' + _result.data ;
            } else {
              let curFile = this.$_.find(this.preview.fileList, { sysAttachFileId: file.sysAttachFileId });
              this.$set(curFile, 'src', ('data:' + file.contentType + ';base64,' + _result.data))
            }
            this.preview.oriFileNm = file.oriFileNm;
            this.preview.fileSize = file.fileSize;
            this.preview.description = file.description;
            this.preview.fileExt = file.fileExt;
          },
          _error => {
          }
        );
      }
    },
    allImagePreView() {
      if (this.fileList && this.fileList.length > 0) {
        // 초기화
        this.preview.fileList = [];
        this.$_.forEach(this.fileList, file => {
          if (file.contentType&&file.contentType.indexOf('image') > -1) {
            this.$set(file, 'src', file.imgUrl)
            this.preview.fileList.push(file)
          }
        })
        let firstFile = this.preview.fileList[0]
        this.preview.slide = firstFile.sysAttachFileId;
        this.preview.isShow = true;
        this.setPreview(firstFile, 'multi');
      } else {
        this.preview.fileList = [];
      }
    },
    transitionImage(val, oldVal) {
      let curFile = this.$_.find(this.preview.fileList, { sysAttachFileId: val });
      if (curFile) {
        this.setPreview(curFile, 'multi');
      }

      let preFile = this.$_.find(this.preview.fileList, { sysAttachFileId: oldVal });
      if (preFile) {
        this.$set(preFile, 'src', preFile.imgUrl)
      }
    },
    removeFile(file) {
      this.$http.url = this.$format(this.deleteUrl, file.sysAttachFileId);
      this.$http.type = 'DELETE';
      this.$http.request((_result) => {
        if (_result.data > 0) {
          this.$q.notify({
            color: 'positive',
            html: true,
            message: this.$language('삭제 완료'), 
            multiLine: true,
            timeout: 5000
          });

          this.$emit('upload-change', 'R');
          this.getUploadedFiles();
        }
      },);
    },
    removeAllFiles() {
      this.$http.url = this.$format(this.allDeleteUrl, this.attachInfo.taskKey, this.attachInfo.taskClassCd);
      this.$http.type = 'DELETE';
      this.$http.request((_result) => {
        if (_result.data > 0) {
          this.$q.notify({
            color: 'positive',
            html: true,
            message: this.$language('삭제 완료'),
            multiLine: true,
            timeout: 5000
          });

          this.$emit('upload-change', 'R');
          this.getUploadedFiles();
        }
      },);
    },
    // async filter(files) {
    //   let returnFiles = [];
    //   let isImageRestriction = Boolean(this.imageRestriction && this.imageRestriction.width && this.imageRestriction.height);
      
    //   if (isImageRestriction) {
    //     if (files && files.length > 0) {
    //       // this.$_.forEach(files, async (file) => {
    //       for (let i = 0; i < files.length; i++) {
    //         let file = files[i];
    //         if (file.type.indexOf('image') > -1) {
    //           // 파일이 이미지로 올라왔으며 사이즈 제한을 걸 시에 이미지의 사이즈를 체크한다.
    //           let isInsert = await this.addImageProcess(file);
    //           console.log(isInsert)
    //           if (isInsert) {
    //             returnFiles.push(file)
    //           } 
    //         } else {
    //           returnFiles.push(file)
    //         }
    //       }
    //       // })
    //     }
    //     console.log('## returnFiles: ', returnFiles)
    //     return returnFiles
    //   } else {
    //     return files
    //   }
    // },
    // addImageProcess(file){
    //   return new Promise((resolve, reject) => {
    //     let _this = this;
    //     let img = new Image()
    //     let url = window.URL || window.webkitURL;
    //     let objectUrl = url.createObjectURL(file);
    //     console.log('## file : ', file)
    //     img.src = objectUrl
    //     img.onload = () => {
    //       console.log(this.width, '  ', this.height, '  ', this)
    //       console.log(_this.imageRestriction.width, '  ', _this.imageRestriction.height)
    //       if (this.width === _this.imageRestriction.width && this.height === _this.imageRestriction.height) {
    //         resolve(true)
    //       } else {
    //         resolve(false)
    //       }
    //     }
    //     img.onerror = reject
    //   })
    // }
  },
};
</script>
<style lang="sass">
.uploaded-file-list
  border-top: 2px solid #1ab394
  // max-height: 200px !important
  overflow-y: auto

.q-uploader--disable
  max-height: 36px !important
  min-height: 20px !important

.empty_dscription
  font-size: 14px
  color: #909399
  margin-bottom: 0 !important

.table-file-origin-name
  line-height: 1.5em!important
  .table-ellipsis
    width: 100px
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

.file-description
  .q-field--with-bottom
    padding-bottom: 0px !important
  .q-field--dense .q-field__inner
    padding-bottom: 0px !important

.preview-image-card 
  .q-carousel 
    height:750px !important
.preview-close-btn
  position: absolute
  right: 5px
  top: 5px
  z-index: 99
</style>