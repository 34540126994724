<!--
목적 : 확장검색 기능을 지원하는 컴포넌트
Detail :
 *
examples:
 *  presentation
-->
<template>
  <div class="fix-height">
    <q-field
      ref="customCheckbox"
      class="q-field--float customCheck"
      :class="items && items.length <= 2 ? 'under_border' : ''"
      :label="convertLabel"
      :value="vValue"
      color="orange-7"
      :rules="[myRule]"
      :disable="!editable || disabled"
      dense
    >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events" :style="isMargin ? 'white-space: pre-wrap;' : ''">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle" v-html="$comm.convertEnter(convertLabel)"></font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        <template v-if="!isFlag">
          <q-option-group
            :options="items"
            :disable="disabled || !editable"
            :name="name"
            :dense="dense"
            :inline="inline"
            val=""
            label=""
            color="orange-7"
            type="checkbox"
            :class="isMargin ? 'q-pb-xs CoptionGroup mt-3' : 'q-pb-xs CoptionGroup'"
            v-model="vValue"
            @input="input" />
        </template>
        <template v-else>
          <div :class="isMargin ? 'q-pb-xs CoptionGroup mt-3' : 'q-pb-xs CoptionGroup'">
              <!-- size="40px" -->
            <q-checkbox
              v-model="vValue"
              :dense="dense"
              color="orange-7"
              :label="setFlagLabel"
              :true-value="trueValue"
              :false-value="falseValue"
              @input="input"
            />
          </div>
        </template>
      </template>
    </q-field>
  </div>
</template>

<script>
import mixinCommon from './js/mixin-common'
export default {
  /* attributes: name, components, props, data */
  name: 'c-checkbox',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: {
      type: [Array, String],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    comboItems: {
      type: Array,
    },
    itemText: {
      type: String,
      default: 'codeName',
    },
    itemValue: {
      type: String,
      default: 'code',
    },
    dense: {
      type: Boolean,
      default: true,
    },
    codeGroupCd: {
      type: String,
      default: ''
    },
    codeAttrVal1: {
      type: String,
      default: ''
    },
    isObject: {
      type: Boolean,
      default: false,
    },
    valueText: {
      type: String,
      default: ''
    },
    valueKey: {
      type: String,
      default: ''
    },
    isFlag: {
      type: Boolean,
      default: false,
    },
    trueValue: {
      type: String,
      default: 'Y'
    },
    falseValue: {
      type: String,
      default: 'N'
    },
    trueLabel: {
      type: String,
      default: 'Yes'
    },
    falseLabel: {
      type: String,
      default: 'No'
    },
    isArray: {
      type: Boolean,
      default: true,
    },
    isMargin: { // 라벨과 체크박스그룹사이에 간격부여 유무
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vValue: {
        type: [Array, String],
        default: null,
      },
      items: [],
    };
  },
  watch: {
    // TODO : 부모의 v-model 변경을 감시(예를 들면, db로부터 데이터를 조회 한 후 값을 바인딩 할 경우)
    value() {
      // this.vValue = this.$_.clone(this.value);
      this.setValue();
    },
    comboItems: {
      handler: function () {
        this.makeSelectOptions(this.comboItems);
        // this.vValue = this.value;
        this.setValue();
      },
      deep: true,
    },
  },
  computed: {
    setFlagLabel() {
      let label = this.trueValue === this.vValue ? this.trueLabel : this.falseLabel
      return label
    }
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {},
  destroyed() {},
  updated() {
  },
  /* methods */
  methods: {
    init() {
      if (this.value && this.value.length > 0) {
        if (!this.isFlag) {
          if (this.$_.isArray(this.value)) {
            this.vValue = this.$_.cloneDeep(this.value);
          } else {
            // String type으로 구분자가 ,로 들어오는 경우는 value값만
            if (this.value !== '') {
              this.vValue = this.$_.split(this.value, ',')
            }
          }
        } else {
          this.vValue = this.$_.clone(this.value)
        }
      } else {
        if (!this.isFlag) {
          this.vValue = [];
        } else {
          this.vValue = this.$_.clone(this.falseValue)
        }
      }
      if (this.comboItems && this.comboItems.length > 0) {
        this.makeSelectOptions(this.comboItems);
      } else if (this.codeGroupCd) {
        this.getItems();
      }
    },
    input() {
      let emitData = [];
      if (!this.isFlag && this.vValue && this.vValue.length > 0) {
        this.$_.forEach(this.vValue, item => {
          /**
           * 다음 두 상황으로 value를 return 할수 있게 처리
           *
           * 1. value만 Array에 담기는 경우
           * 2. Object로 itemText, itemValue에 맞춰 Array에 담기는 경우
           */
          if (this.isObject) {
            // case 1
            let data = this.$_.find(this.items, { value: item })
            let pushData = {}
            pushData[this.valueText] = data.label
            pushData[this.valueKey] = data.value
            emitData.push(pushData);
          } else {
            // case 2
            emitData.push(item);
          }
        })
      } else if (this.isFlag) {
        this.$emit('input', this.vValue);
        this.$emit('datachange', this.vValue);
        return
      }
      
      if (!this.isArray) {
        emitData = emitData.toString();
      }


      this.$emit('input', emitData);
      this.$emit('datachange', this.vValue);
    },
    getItems() {
      this.$comm.getComboItems(this.codeGroupCd).then(_result => {
        this.makeSelectOptions(_result);
        this.$emit('setCodeData')
      });
    },
    makeSelectOptions(items) {
      let options = [];
      if (!items || items.length === 0) return options;
      let tempcomboItems = [];
      // 쓰기권환이 있으며 활성화 상태인 경우
      if (this.editable && !this.disabled) {
        // 사용여부가 Y인 것만 리스트에 표현한다.
        // default : 사용여부 상관없이 전체
        tempcomboItems = this.$_.reject(items, { useFlag: 'N' });
      } else {
        tempcomboItems = items;
      }
      // 추가속성으로 조회조건이 추가적으로있을경우 필터링
      if (this.codeAttrVal1) {
        tempcomboItems = this.$_.filter(tempcomboItems, {attrVal1 : this.codeAttrVal1 });
      }

      this.$_.forEach(tempcomboItems, (_item) => {
        options.push({
          label: _item[this.itemText],
          value: _item[this.itemValue],
        });
      });
      this.items = options;

      // items를 컴포넌트에서 조회를 하는 경우 setValue method를 타는 시점에서 items가 없으므로 해당 setValue 처리
      this.setValue();
    },
    setValue() {
      let tempValue = []; // this.$_.cloneDeep(this.value);
      if (this.$_.isArray(this.value)) {
        tempValue = this.$_.cloneDeep(this.value);
      } else {
        // String type으로 구분자가 ,로 들어오는 경우는 value값만
        if (this.value !== '') {
          tempValue = this.$_.split(this.value, ',')
        }
      }

      if (this.items && this.items.length > 0) {
        let data = [];
        if (!this.isFlag && tempValue && tempValue.length > 0) {
          this.$_.forEach(tempValue, item => {
            /**
             * 다음 두 상황으로 v-model을 받을 수 있게 처리
             *
             * 1. value만 Array에 담기는 경우
             * 2. Object로 itemText, itemValue에 맞춰 Array에 담기는 경우
             */
            let pushValue = '';
            let findData = null;
            if (this.isObject) {
              // case 1
              findData = this.$_.find(this.items, { value: item[this.valueKey] });
            } else {
              // case 2
              findData = this.$_.find(this.items, { value: item });
            }
            if (findData) {
              pushValue = findData.value
            }
            data.push(pushValue)
          });
        }


        if (data) {
          this.vValue = data;
        } else {
          this.vValue = [];
        }
      } else {
        if (!this.isFlag) {
          this.vValue = [];
        } else {
          // 단일형인 경우에는 해당 값은 String형태로 넘어오며 값을 v-model로 넣어 처리
          this.vValue = this.$_.clone(this.value);
        }
      }
    },
    myRule(val) {
      return !this.required || (val && val.length > 0) || ''
    }
  },
};
</script>
<style lang="sass">
.CoptionGroup
  padding-left: 0px
  padding-bottom: 0px
  margin-bottom: -10px
  font-size: 1.15em
  .q-checkbox--dense
    .q-checkbox__inner
      width: 0.9rem !important
      min-width: 0.9rem !important
      height: 0.9rem !important
  .q-checkbox__label
    padding-left: 0.2em
    padding-right: 0.6em
    font-size: 0.9rem
  .q-checkbox__bg
    border-width: 1px !important
.customCheck
  .q-field__label
    margin-left: 5px
  .text-negative
    margin-top: 15px
  .q-field__native
    padding-bottom: 6px !important
  .q-checkbox
    padding: 5px 5px 5px 0px
.customCheck.q-field--dense .q-field__control, .customCheck.q-field--dense .q-field__marginal
  height: min-content !important
  min-height: 39px !important

.customCheck.q-field--filled .q-field__control
  border-width: 0px !important
  border-bottom: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px

.under_border.q-field--filled .q-field__control
  border-width: 0 0 1px 0 !important
  border-color: rgba(0, 0, 0, 0.15) !important
  border-style: solid !important
</style>