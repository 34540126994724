<!--
목적 : 작업허가서 팝업 컴포넌트
-->
<template>
  <div class="fix-height">
    <q-field
      ref="customWorkPermit"
      class="customWorkPermit"
      :dense="dense"
      stack-label
      bottom-slots
      color="orange-7"
      :label="convertLabel"
      :disable="disabled||!editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="valueText"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{convertLabel}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        {{valueText}}
      </template>

      <template v-slot:append>
        <q-icon v-if="!disabled&&editable" name="search" @click="search" class="cursor-pointer"></q-icon>
        <q-icon v-if="!disabled&&editable" name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-field>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import mixinCommon from './js/mixin-common'
export default {
  name: 'c-work-permit',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
      default: 'field',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: { // 사용자의 id를 받아 처리
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    plantCd: {
      type: String,
      default: '',
    },
    isRemoveConfirm: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      vValue: null,
      valueText: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '60%',
        param: {},
        closeCallback: null,
      },
      workPermitList: null,
    };
  },
  computed: {
  },
  watch: {
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
        if (this.value !== null) {
          this.vValue = this.value;
          this.getList();
          // this.setValueText();
        } else {
          this.$emit('input', null);
          this.valueText = '';
        }
      },
      deep: true,
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    this.vValue = this.value;
    this.listUrl = selectConfig.sop.swp.workPermit.list.url
    this.setValueText();
  },
  mounted() {
    if (!this.disabled) {
      this.$refs['customWorkPermit'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  beforeDestroy() {
    if (!this.disabled) {
      this.$refs['customWorkPermit'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  methods: {
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {};
      this.$http.request((_result) => {
        this.workPermitList = _result.data;
        this.setValueText();
      },);
    },
    /**
     * v-model로 받은 값을 통해 사용자 정보 조회
     * type에 따라 valueText에 표시되는 값 변경
     */
    setValueText() {
      if (this.vValue) {
        if (!this.workPermitList) {
          this.getList();
        } else if (this.workPermitList.length === 0) {
          this.$emit('input', null);
          this.valueText = '';
        } else {
          let workPermit = this.$_.find(this.workPermitList, { sopWorkPermitId: this.vValue });
          if (workPermit) {
            this.valueText = workPermit.permitNo +" | "+ workPermit.permitDate
          } else {
            this.$emit('input', null);
            this.valueText = '';
          }
        }
      } else {
        this.valueText = '';
      }
    },
    search() {
      this.popupOptions.title = "작업허가서 검색"; 
      this.popupOptions.param = {
        type: "single",
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/swp/safeWorkPermitPop.vue"}`);
      this.popupOptions.width = "100%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.workPermitList = data;
        this.valueText = data[0].permitNo +" | " + data[0].permitDate
        this.$emit('input', data[0].sopWorkPermitId);
        this.$emit('dataChange', data[0]);
      }
    },
    reset() {
      if(this.isRemoveConfirm) {
        window.getApp.$emit("CONFIRM", {
          title: "확인",
          message: "해당 작업허가서에 지정된 기본 정보들과 작업자가 삭제됩니다.\n\r진행하시겠습니까?",
          type: "info", // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.valueText = '';
            this.$emit('input', null);
            this.$emit('dataChange', null);
            this.$emit('remove', null);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }else {
        this.valueText = '';
        this.$emit('input', null);
        this.$emit('dataChange', null);
        this.$emit('remove', null);
      }
    },
  },
};
</script>
<!-- <style lang="sass">
.customWorkPermit
  padding-bottom: 20px !important
  .q-field__native.row
    padding-left: 3px
    font-size: 0.95em
    padding-top: 4px !important
  .q-field__native
    padding-bottom: 6px !important
    cursor: pointer
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8rem
  .dateCloseIcon
    font-size: 0.8rem !important
    display: none
.customWorkPermit:hover
  .dateCloseIcon
    display: block
.customWorkPermit.q-field--dense .q-field__control, .customWorkPermit.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 33px !important
.customWorkPermit.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px
</style> -->