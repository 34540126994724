/* eslint-disable */
import Cookies from 'js-cookie'
import {
  getLangInfo,
} from './login';
//싸고 있는 app에서 쿠키를 저장, 다른 메뉴를 클릭 할 때마다 쿠키를 저장, 초기화진행
const app = {
  state: {
    searchAreaToggle: false,
    device: 'desktop',
    language: Cookies.get('language') || 'kr',
    langInfo: {
      label: null,
      message: null,
    },
    loading: false,
  }, //돌연변이: 검색박스가 보여진 상태일 때 닫히는 상태로, 때마다 쿠키저장
  mutations: {
    SEARCH_AREA_TOGGLE: (state, searchAreaShow) => {
      state.searchAreaToggle = searchAreaShow
    },
    CLEAR_SEARCH_BOX: state => {
      state.searchAreaToggle = false
    },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language
      Cookies.set('language', language)
    },
    SET_LANG_INFO: (state, loginLang) => {
      state.loginLang = loginLang;
    },
    SET_LOADING: (state, loading) => {
      state.loading = loading;
    },
  }, //commit 수행, 객체처럼 넘겨줌
  actions: {
    searchAreaToggle({
      commit
    }, searchAreaShow) {
      commit('SEARCH_AREA_TOGGLE', searchAreaShow)
    },
    clearSearchBox({
      commit
    }) {
      commit('CLEAR_SEARCH_BOX')
    },
    toggleSideBar({
      commit
    }) {
      commit('TOGGLE_SIDEBAR')
    },
    closeSideBar({
      commit
    }, {
      withoutAnimation
    }) {
      commit('CLOSE_SIDEBAR', withoutAnimation)
    },
    toggleDevice({
      commit
    }, device) {
      commit('TOGGLE_DEVICE', device)
    },
    setLanguage({
      commit
    }, language) {
      commit('SET_LANGUAGE', language);
    },
    setLoading({
      commit
    }, loading) {
      commit('SET_LOADING', loading);
    },
  }
}

export default app
