<!--
  TODO : 버튼 공통 모듈
-->
<template>
  <q-card bordered flat class="custom-card" :class="[topClass, (noMarginPadding ? 'padding-margin-none' : '')]">
    <q-card-section v-if="!noHeader" class="q-pa-xs cardhead" :class="'bg-' + bgClass">
      <div class="row items-center no-wrap">
        <div v-if="isTitle && Boolean(this.title)" class="col">
          <div v-if="isTitle" class="text-h6" :class="'text-' + titleClass">
            <i class="pe-7s-angle-right-circle" :class="'text-' + titleClass"></i>
            {{convertTitle}}
            <slot name="card-description"></slot>
          </div>
        </div>
        <div class="col-auto">
          <slot name="card-button"></slot>
        </div>
        <div class="float-right">
          <q-btn
            v-if="collapsed"
            flat
            dense
            class="card-collapse"
            color="grey-6"
            :icon="expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
            @click="expanded = !expanded">
          </q-btn>
        </div>
      </div>
    </q-card-section>
    <q-separator v-if="!noHeader"  />
    <q-card-section v-show="expanded"
      class="q-pa-sm row cardheader customCardbody"
      :style="height ? { 'height': height, 'min-height': height, 'max-height': height } : { 'height': 'auto' }">
      <slot name="card-detail"></slot>
    </q-card-section>
  </q-card>
</template>

<script>
import mixinCommon from './js/mixin-common'
export default {
  /* attributes: name, components, props, data */
  name: 'c-card',
  mixins: [mixinCommon],
  props: {
    title: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: 'auto',
    },
    bgClass: {
      type: String,
      default: 'grey-3',
    },
    titleClass: {
      type: String,
      default: 'grey-8',
    },
    collapsed: { // 카드 접기/펼치기 기능 사용여부
      type: Boolean,
      default: false,
    },
    noHeader: { // 헤더사용/미사용
      type: Boolean,
      default: false,
    },
    topClass:  {
      type: String,
      default: '',
    },
    noMarginPadding:  {
      type: Boolean,
      default: false,
    },
    isTitle:  {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      expanded: true,
    };
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  mounted() {
  },
  /* methods */
  methods: {
    clickCardCollapse() {

    }
  },
};
</script>
<style lang="sass">

.custom-card
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important
  border-radius: 0px !important
  .cardhead
    padding: 0px 5px 0px 10px
    .text-h6
      font-size: 1.05em
      font-weight: 600
      line-height: 26px
      i.pe-7s-angle-right-circle
        font-weight: 600
  .card-collapse
    font-size:10px
    width: 20px
.q-btn-group
  button
    margin-right: 2px
  button:last-child
    margin-right: 0px
.customCardbody
  padding: 12px 0 0 0 !important
  overflow-y: auto
  margin-top: 10px !important

.padding-margin-none
  .customCardbody
    padding: 0px !important
    margin: 0px !important
    // padding-bottom: 0px !important
    // margin-bottom: 0px !important


.cardClassDetailFormLine .q-pa-sm
  padding-top: 0px !important
  margin-top: 0px !important
</style>