var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-dialog",
        {
          attrs: {
            "no-esc-dismiss": !_vm.param.escDismiss,
            "no-backdrop-dismiss": !_vm.param.backdropDismiss,
            "content-class": {
              customDialog: true,
              "full-dialog": _vm.param.isFull,
              "mini-dialog": !_vm.param.isFull,
            },
            "content-style": { opacity: _vm.bodyOpacity },
            position: _vm.param.position
              ? _vm.param.position
              : !_vm.param.isFull
              ? "bottom"
              : "bottom",
          },
          model: {
            value: _vm.param.visible,
            callback: function ($$v) {
              _vm.$set(_vm.param, "visible", $$v)
            },
            expression: "param.visible",
          },
        },
        [
          _c(
            "q-layout",
            {
              ref: "dialog-layout",
              staticClass: "bg-white no-scroll",
              style: {
                "max-width": _vm.param.isFull ? "none" : _vm.param.width,
                "min-height": "auto",
                "max-height": _vm.param.isFull ? "100%" : "calc(100vh - 50px)",
              },
              attrs: { view: "Lhh lpR fff" },
            },
            [
              _c(
                "q-header",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.param.title,
                      expression: "param.title",
                    },
                  ],
                  ref: "dialog-header",
                  staticClass: "text-white",
                  class: _vm.param.isFull
                    ? _vm.param.isApproval
                      ? "bg-purple-8"
                      : "bg-indigo-8"
                    : "bg-orange-7",
                },
                [
                  _c(
                    "q-toolbar",
                    [
                      _c("q-toolbar-title", [
                        _vm._v(_vm._s(_vm.$language(_vm.param.title))),
                      ]),
                      _c("q-btn", {
                        attrs: {
                          flat: "",
                          round: "",
                          dense: "",
                          icon: "close",
                        },
                        on: { click: _vm.closePopup },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-drawer",
                {
                  attrs: {
                    side: "right",
                    bordered: "",
                    width: 200,
                    breakpoint: 300,
                    "content-class": "bg-grey-3 q-pa-sm",
                  },
                  model: {
                    value: _vm.subMenuOpen,
                    callback: function ($$v) {
                      _vm.subMenuOpen = $$v
                    },
                    expression: "subMenuOpen",
                  },
                },
                _vm._l(50, function (n) {
                  return _c("div", { key: n }, [
                    _vm._v("Drawer " + _vm._s(n) + " / 50"),
                  ])
                }),
                0
              ),
              _c(
                "q-page-container",
                {
                  ref: "dialog-container",
                  staticClass: "custom-dialog-page-container",
                  style: {
                    "overflow-y": "auto",
                    "min-height": "auto",
                    "max-height": _vm.param.isFull
                      ? "calc(100vh)"
                      : "calc(100vh - 100px)",
                  },
                  attrs: { container: "", id: "dialog-container" },
                },
                [
                  _c(
                    "q-page",
                    {
                      staticStyle: { "min-height": "200px" },
                      attrs: { padding: "" },
                    },
                    [
                      _c(
                        "div",
                        {
                          style: {
                            height: _vm.param.isFull
                              ? "calc(100vh - 120px)"
                              : "auto",
                          },
                        },
                        [
                          _c("q-scroll-observer", {
                            attrs: {
                              "scroll-target": _vm.$refs["dialog-container"],
                            },
                            on: { scroll: _vm.onScroll },
                          }),
                          _c(_vm.param.target, {
                            tag: "component",
                            style: `background:` + _vm.bodyColor,
                            attrs: {
                              popupParam: _vm.param.param,
                              contentHeight: _vm.contentHeight,
                              returnData: _vm.returnData,
                              eventScroll: _vm.eventScroll,
                            },
                            on: {
                              "update:eventScroll": function ($event) {
                                _vm.eventScroll = $event
                              },
                              "update:event-scroll": function ($event) {
                                _vm.eventScroll = $event
                              },
                              closePopup: _vm.param.closeCallback,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.param.isFull,
                              expression: "!param.isFull",
                            },
                          ],
                          staticClass: "popupBackBtn2",
                        },
                        [
                          _c("q-btn", {
                            attrs: {
                              flat: "",
                              color: "white",
                              "text-color": "white",
                              icon: "close",
                            },
                            on: { click: _vm.param.closeCallback },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }