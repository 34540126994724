<!--
목적 : 확장검색 기능을 지원하는 컴포넌트
Detail :
 *
examples:
 *
-->
<template>
  <div v-if="isUploaderSetting" class="fix-height row upload-cover2">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <q-list separator bordered class="uploaded-file-list2">
        <template v-if="fileList&&fileList.length > 0">
          <q-item v-for="(file, idx) in fileList" :key="idx" class="fileuploadItem">
            <q-item-section class="fileuploadColumn">    
              <q-item-label class="full-width file-origin-name" >
                <span class="text-blue cursor-pointer" @click.prevent="fileDown(file)" style="font-size: 0.95em !important">
                  {{ file.oriFileNm }}
                </span>
                <span class="text-caption">
                  ({{ getFileSizeTextByRound(file.fileSize) }})
                </span>
              </q-item-label>
              <q-item-label v-if="uploaderSetting.explainFlag==='Y'">
                <c-text
                  :editable="editable"
                  class="file-description"
                  name="description"
                  :placeholder="$language('파일설명')"
                  v-model="file.description">
                </c-text>
              </q-item-label>
            </q-item-section>
          </q-item>
        </template>
        <template v-else>
          <q-item class="fileuploadItem nofileArea2">
            {{$language('업로드된 파일이 없습니다.')}}
          </q-item>
        </template>
      </q-list>
    </div>
    
    <!-- 이미지 미리보기 -->
    <q-dialog v-if="preview&&(preview.src || (preview.fileList && preview.fileList.length > 0))" 
      v-model="preview.isShow">
      <q-card class="preview-image-card">
        <q-carousel
          v-if="preview.fileList && preview.fileList.length > 0"
          swipeable animated thumbnails infinite
          v-model="preview.slide"
          @transition="transitionImage"
        >
          <q-carousel-slide
            v-for="(file, idx) in preview.fileList"
            :key="idx"
            :name="file.sysAttachFileId" 
            :img-src="file.src"></q-carousel-slide>
        </q-carousel>
        <q-img v-else :src="preview.src"></q-img>
        <q-card-section>
          <q-btn
            fab
            color="primary"
            icon="save_alt"
            class="absolute"
            style="top: 0; right: 12px; transform: translateY(-50%);"
            @click.prevent="fileDown(preview)"
          ></q-btn>
          <div class="row no-wrap items-center">
            <div class="col text-h6">
              {{preview.oriFileNm}}
            </div>
            <div class="col-auto text-grey text-caption q-pt-md row no-wrap items-center">
              {{ getFileSizeTextByRound(preview.fileSize) }}
            </div>
          </div>
        </q-card-section>
        <q-separator v-if="preview.description"></q-separator>
        <q-card-section v-if="preview.description" class="q-pt-none">
          <div class="text-subtitle1">
            <q-icon name="info"></q-icon>  
            {{$language('파일 설명')}}
          </div>
          <div class="text-caption text-grey">
            {{preview.description}}
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import backendConfig from '@/js/backendConfig.js';
import { getAccessToken } from '@/utils/auth';
import mixinCommon from './js/mixin-common'
export default {
  /* attributes: name, components, props, data */
  name: 'c-upload-list',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '첨부파일',
    },
    maxheight: {
      type: String,
      default: 'width: 100%;',
    },
    attachInfo: {
      type: Object,
      default: function() {
        return {
          isSubmit: '',
          taskClassCd: '',
          taskKey: '',
        }
      }
    },
    imageRestriction: {
      type: Object,
      default: function() {
        return { 
          width: 0,
          height: 0,
        }
      }
    },
  },
  data() {
    return {
      preview: {
        slide: 1,
        isShow: false,
        sysAttachFileId: '',
        src: '',
        oriFileNm: '',
        fileSize: 0,
        description: '',
        fileExt: '',
        fileList: [],
      },
      isUploaderSetting: false,
      uploaderSetting: {
        multipleSelFlag: 'N',
        previewFlag: 'N',
        acceptExt: '*',
        limitSize: 1048576,
        limitCnt: 5,
        noThumbnails: false,
        explainFlag: 'N'
      },
      fileList: [],
      uploderSettingUrl: '',
      uploadedFilesUrl: '',
      tempTaskKeyUpdateUrl: '',
      allDeleteUrl: '',
      deleteUrl: '',
      previewUrl: '',
      fileDownUrl: '',
    };
  },
  computed: {
    url() {
      return backendConfig.getUrl(transactionConfig.com.upload.uploading.url);
    },
    headers() {
      return [
        { name: 'X-Authorization', value: getAccessToken(), }
      ];
    },
    formFields() {
      let data = [
        {
          name: 'regUserId',
          value: this.$store.getters.user.userId,
        },
        {
          name: 'chgUserId',
          value: this.$store.getters.user.userId,
        }
      ];
      if (this.attachInfo) {
        if (this.attachInfo.taskClassCd) {
          data.push({
            name: 'taskClassCd',
            value: this.attachInfo.taskClassCd,
          })
        }
        /**
         * 신규인 경우 taskKey가 없을 수 있음
         * 해당의 경우 api-server에서 유니크한 id를 생성하여 저장 후 반환한다.
         */
        if (this.attachInfo.taskKey) {
          data.push({
            name: 'taskKey',
            value: this.attachInfo.taskKey,
          })
        } else {
          let val = this.attachInfo.taskClassCd + '_' + uid();
          data.push({
            name: 'taskKey',
            value: val,
          })
          this.$set(this.attachInfo, 'taskKey', val)
        }
      }
      return data;
    },
    maxFiles() {
      let fileCnt = this.fileList ? this.fileList.length : 0;
      return this.uploaderSetting.limitCnt - fileCnt
    }
  },
  watch: {
    'attachInfo.taskClassCd'() {
      this.setUploderSetting();
      this.getUploadedFiles();
    },
    'attachInfo.taskKey'() {
      this.getUploadedFiles();
    },
    'attachInfo.isSubmit'() {
      if (this.fileList && this.fileList.length > 0) {
        this.$_.forEach(this.fileList, file => {
          this.$set(file, 'taskKey', this.attachInfo.taskKey)
        })
      }
      // 첨부 저장하는 단계
      this.$http.url = this.tempTaskKeyUpdateUrl;
      this.$http.type = 'PUT';
      this.$http.param = this.fileList;
      this.$http.request((_result) => {
        if (_result.data) {
          // 신규로 등록됨에 따라 임시로 만든 taskKey를 반환처리
          this.getUploadedFiles();
        }
      },);
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {},
  destroyed() {},
  updated() {
  },
  /* methods */
  methods: {
    init() {
      // url setting
      this.uploderSettingUrl = selectConfig.sys.attach.get.url;
      this.uploadedFilesUrl = selectConfig.com.upload.fileList.url
      this.tempTaskKeyUpdateUrl = transactionConfig.com.upload.save.url
      this.allDeleteUrl = transactionConfig.com.upload.allDelete.url
      this.deleteUrl = transactionConfig.com.upload.delete.url
      this.previewUrl = selectConfig.com.upload.preview.url
      this.fileDownUrl = selectConfig.com.upload.fileDown.url
      
      this.setUploderSetting();
      this.getUploadedFiles();
    },
    getFileSizeTextByRound(_fileByteSize) {
      return this.$comm.bytesToSize(_fileByteSize);
    },
    finish() {
      this.$refs['custom-upload'].removeUploadedFiles();
      this.$refs['custom-upload'].reset();
      this.getUploadedFiles();
    },
    uploaded(info) { },
    failed(info) {
      let message = '파일 [';
      if (info && info.files && info.files.length > 0) {
        let idx = 0;
        this.$_.forEach(info.files, file => {
          message += '"' + file.name + (idx !== (info.files.length - 1) ? '", ' : '"] ')
          idx++;
        });
        message += this.$language('업로드에 실패하였습니다.')
      }
      this.$q.notify({
        color: 'negative',
        html: true,
        message: message,
        multiLine: true,
        timeout: 5000
      })
    },
    rejected(info) {
      if (!info || info.length === 0) {
        return;
      }
      let message = '';
      this.$_.forEach(info, reject => {
        // accept, max-file-size, max-total-size, filter, etc
        switch(reject.failedPropValidation) {
          case 'max-file-size': // 파일용량 초과
          case 'max-total-size': // 파일 전체 용량 초과
            message += this.$language('첨부하신 파일의 용량이 지정된 용량보다 큽니다. 파일 용량 : ') + '(' +  this.getFileSizeTextByRound(reject.file.size) + ')';
            break;
          case 'max-files': // 업로드 갯수 초과
            message = this.$language('첨부하신 파일의 지정된 업로드 갯수를 초과하여 업로드 되지 않았습니다.') + '(' +  this.uploaderSetting.limitCnt + ')';
            break;
          case 'accept': // 확장자 맞지않음
            message = this.$language('첨부하신 파일의 확장자가 올바르지 않습니다.')+ '(' +  this.uploaderSetting.acceptExt + ')';
            break;
          case 'filter': // filter 걸린경우
          // 해당 기능 사용하지 않음으로 다국어 처리하지 않음
            message = '첨부하신 이미지 "' + reject.file.name + '"의 사이즈가 올바르지 않습니다. (사이즈 : ' + this.imageRestriction.width + ' X ' + this.imageRestriction.height + ')';
            break;
          default:
            break;
        }
      })
      this.$q.notify({
        color: 'negative',
        html: true,
        message: message,
        multiLine: true,
        timeout: 5000
      })
    },
    setUploderSetting() {
      if (!this.attachInfo.taskClassCd) return
      this.$http.url = this.$format(this.uploderSettingUrl, this.attachInfo.taskClassCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        if (_result.data) {
          this.isUploaderSetting = true;
          this.$_.extend(this.uploaderSetting, _result.data)
        }
      },);
    },
    getUploadedFiles() {
      if (!this.attachInfo.taskClassCd || !this.attachInfo.taskKey) return
      this.$http.url = this.uploadedFilesUrl;
      this.$http.type = 'GET';
      this.$http.param = this.attachInfo;
      this.$http.request((_result) => {
        if (_result.data) {
          // 신규로 등록됨에 따라 임시로 만든 taskKey를 반환처리
          this.fileList = _result.data;
          if (this.fileList && this.fileList.length > 0) {
            this.$_.forEach(this.fileList, item => {
              let encstr = item.previewImage;
              let url = window.URL || window.webkitURL;
              let blob = this.$comm.base64ToBlob(encstr, 'image/jpeg');
              let imgUrl = url.createObjectURL(blob);
              item.imgUrl = imgUrl;
            })
          }
        }
      },);
    },
    fileDown(file) {
      let thisVue = this;
      let accept = this.$comm.getAccept(file.fileExt);
      this.$http.url = this.fileDownUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        sysAttachFileId: file.sysAttachFileId,
      };
      this.$http.request(
        _result => {
          let url = window.URL || window.webkitURL;
          let link = document.createElement('a');
          let blob = thisVue.$comm.base64ToBlob(_result.data, accept);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, file.oriFileNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = file.oriFileNm;
            link.click();
          }
        },
      );
    },
    haveImage() {
      if (this.fileList && this.fileList.length > 0) {
        let flag = false;
        this.$_.forEach(this.fileList, file => {
          if (file.contentType&&file.contentType.indexOf('image') > -1) {
            flag = true;
            return false;
          }
        })
        return flag;
      } else {
        return false;
      }
    },
    setPreview(file, type) {
      if (!file) return;
      // 이미지인  경우
      if (file.sysAttachFileId) {
        if (type === 'single') {
          this.preview.fileList = [];
        }
        this.$http.url = this.previewUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          sysAttachFileId: file.sysAttachFileId,
        };
        this.$http.request(
          _result => {
            this.preview.isShow = true;
            this.preview.sysAttachFileId = file.sysAttachFileId;
            if (type === 'single' ) {
              this.preview.src = 'data:' + file.contentType + ';base64,' + _result.data ;
            } else {
              let curFile = this.$_.find(this.preview.fileList, { sysAttachFileId: file.sysAttachFileId });
              this.$set(curFile, 'src', ('data:' + file.contentType + ';base64,' + _result.data))
            }
            this.preview.oriFileNm = file.oriFileNm;
            this.preview.fileSize = file.fileSize;
            this.preview.description = file.description;
            this.preview.fileExt = file.fileExt;
          },
          _error => {
          }
        );
      }
    },
    allImagePreView() {
      if (this.fileList && this.fileList.length > 0) {

        this.$_.forEach(this.fileList, file => {
          if (file.contentType&&file.contentType.indexOf('image') > -1) {
            this.$set(file, 'src', file.imgUrl)
            this.preview.fileList.push(file)
          }
        })
        let firstFile = this.fileList[0]
        this.preview.slide = firstFile.sysAttachFileId;
        this.preview.isShow = true;
        this.setPreview(firstFile, 'multi');
      } else {
        this.preview.fileList = [];
      }
    },
    transitionImage(val, oldVal) {
      let curFile = this.$_.find(this.preview.fileList, { sysAttachFileId: val });
      if (curFile) {
        this.setPreview(curFile, 'multi');
      }

      let preFile = this.$_.find(this.preview.fileList, { sysAttachFileId: oldVal });
      if (preFile) {
        this.$set(preFile, 'src', preFile.imgUrl)
      }
    },
    removeFile(file) {
      this.$http.url = this.$format(this.deleteUrl, file.sysAttachFileId);
      this.$http.type = 'DELETE';
      this.$http.request((_result) => {
        if (_result.data > 0) {
          this.$q.notify({
            color: 'positive',
            html: true,
            message: this.$language('삭제 완료'),
            multiLine: true,
            timeout: 5000
          });
          this.getUploadedFiles();
        }
      },);
    },
    removeAllFiles() {
      this.$http.url = this.$format(this.allDeleteUrl, this.attachInfo.taskKey, this.attachInfo.taskClassCd);
      this.$http.type = 'DELETE';
      this.$http.request((_result) => {
        if (_result.data > 0) {
          this.$q.notify({
            color: 'positive',
            html: true,
            message: this.$language('삭제 완료'),
            multiLine: true,
            timeout: 5000
          });
          this.getUploadedFiles();
        }
      },);
    },
  },
};
</script>
<style lang="sass">
.custom-upload
  .q-btn
    margin-top: 0px !important
    margin-left: 0px !important
.custom-upload.q-uploader--disable
    min-height: 200px !important
.upload-cover2
  margin-top: -20px !important
  height: auto
.uploaded-file-list2
  border-top: 2px solid #1976d2
  overflow-y: hidden

.q-uploader--disable
  max-height: 36px !important
  min-height: 20px !important

.empty_dscription
  font-size: 14px
  color: #909399
  margin-bottom: 0 !important

.file-origin-name
  line-height: 2em!important

.file-description
  .q-field--with-bottom
    padding-bottom: 0px !important
  .q-field--dense .q-field__inner
    padding-bottom: 0px !important

.preview-image-card 
  min-width: 400px
  .q-carousel 
    height:750px !important
.txtfileinfo
  padding-left: 0px !important
  word-break: break-all
  font-size: 12px
.nofileArea2
  height: 50px
  font-size: 0.95em !important

</style>