import Vue from 'vue'
import moment from 'moment';
import _ from 'lodash';
import Cookies from 'js-cookie'

import $format from 'string-format';
import $store from '@/store';

import selectConfig from '@/js/selectConfig';
import XLSX from 'xlsx'

moment.updateLocale('ko', {
	week:{
    dow : 1, // 한 주의 시작 요일은 일요일(0)
    doy: 4 //  연도의 첫 주는 1월 3일을 포함해야 될 경우. (계산 식: 7+dow-3)
  }
})

/* eslint-disable no-unused-vars */
let comm = {
  movePage: null,
  movePageReplace: null,
  clone: null,
  today: new Date(moment().format()),
  getToday: null,
  getTodayNone: null,
  getTodayDateTime: null,
  getTodayDateTiemMin: null,
  getTodayHan: null,

  getFirstDayThisYear: null,
  getLastDayThisYear: null,
  getThisMonth: null,
  getPrevMonth: null,
  getAddMonth: null,
  getThisYear: null,
  getPrevYear: null,
  getCalculatedDate: null,
  getLastDayOfMonth: null,
  getDatediff: null,
  fixedStr: null,
  moment: moment,
  setNumberSeperator: null,
  defaultGapOfDate: '6d',
  removeDuplicatedArray: null,
  removeDuplicatedArrayObject: null,
  isDateType: null,
  fileDownload: null,
  openCheckPwdPopup: null,
  goCheckPassword: null,
  goLogin: null,
  base64ToBlob: null,
  bytesToSize: null,
  getFileExtIcon: null,
  getAccept: null,
  isStringEmpty: null,
  toUndrFromCamelCase: null,
  telephoneMask: null,
  generateRandom: null,
  generateRandomComma: null,
  setCookie: null,
  getCookie: null,
  pushCookie: null,
  stringToBoolean: null,
  // array를 tree형태로 변환
  recursivePromise: null,
  orderedPromise: null,
  getComboItems: null,
  getMultiComboItems: null,
  hexToDecimal: null,
  decimalToHex: null,
  getTextColorByBackgroundColor: null,
  getLang: null,
  getLangSpecInfoLabel: null,
  getLangSpecInfoMessage: null,
  validTable: null,
  getStepItems: null,
  convertHtml: null,
  previewImage: null,
  previewImageParamTask: null,
  uploderSetting: null,
  getHeaders: null,
  safe_decode_range: null,
  convertEnter: null,
  ibmTagItems: null,
  inspectIbmTagItems: null,
  fmImprTagItems: null,
  getLangLabelJsonApi: null,
  getLangMessageJsonApi: null,
  getLanguageJsonApi: null,
  getLangLabel: null,
  getLangMessage: null,
  getLanguage: null,
};


comm.movePage = function (_$router, _path) {
  _$router.push({
    path: _path,
  });
};

comm.movePageReplace = function (_$router, _path) {
  _$router.replace({
    path: _path,
  });
};

/**
 * function name : clone
 * remark : JSON object 복제
 * @param {*} _json : 원본 JSON object
 */
comm.clone = function (_json) {
  return JSON.parse(JSON.stringify(_json));
};

comm.getToday = function (_isLocalSet) {
  let dateFormat = _isLocalSet ? 'L' : 'YYYY-MM-DD'; // default : ISO format('YYYY-MM-DD)
  return moment().format(dateFormat);
};
comm.getTodayNone = function (_isLocalSet) {
  let dateFormat = _isLocalSet ? 'L' : 'YYYYMMDD'; // default : ISO format('YYYY-MM-DD)
  return moment().format(dateFormat);
};
comm.getTodayHan = function (_isLocalSet) {
  let dateFormat = _isLocalSet ? 'L' : 'YYYY년 MM월 DD일'; // default : ISO format('YYYY-MM-DD)
  return moment().format(dateFormat);
};

comm.getTodayDateTime = function () {
  let dateFormat = 'YYYY-MM-DD HH:mm:ss';
  return moment().format(dateFormat);
};
comm.getTodayDateTiemMin = function () {
  let dateFormat = 'YYYY-MM-DD HH:mm';
  return moment().format(dateFormat);
};

comm.getFirstDayThisYear = function () {
  return comm.today.getFullYear() + '-01-01';
};

comm.getLastDayThisYear = function () {
  return comm.today.getFullYear() + '-12-31';
};

/**
 * 날짜를 받아와서 두 날의 차이일수를 가져오는 함수
 * @param {*필수} _firstDate : 비교날짜 1
 * @param _secondDate : 비교날짜 2, 없으면
 * @return 차이일수
 */
comm.getDatediff = function (_firstDate, _secondDate) {
  if (!_firstDate) return null;
  if (!_secondDate) _secondDate = comm.today;
  return Math.round((_secondDate - _firstDate) / (1000 * 60 * 60 * 24));
};

comm.getThisMonth = function (_format) {
  let formatL = '';
  _format = _format ? _format : 'YYYY-MM';
  if (_format === 'locale') {
    formatL = moment.localeData().longDateFormat('L');
    _format = formatL.replace(/D/g, '').replace(/^\W|\W$|\W\W/, '');
  }
  return moment().format(_format);
};

comm.getPrevMonth = function (_prev, _format) {
  let formatL = '';
  _format = _format ? _format : 'YYYY-MM';
  if (_format === 'locale') {
    formatL = moment.localeData().longDateFormat('L');
    _format = formatL.replace(/D/g, '').replace(/^\W|\W$|\W\W/, '');
  }
  return moment()
    .add(Math.abs(_prev) * -1, 'M')
    .format(_format);
};

comm.getAddMonth = function (_date, _cnt) {
  return moment(_date).add(_cnt, 'M').add((_cnt > 0 ? -1 : 1), 'd').format('YYYY-MM-DD');
};

comm.getThisYear = function () {
  return moment().format('YYYY');
};

comm.getPrevYear = function (_prev) {
  return moment()
    .add(Math.abs(_prev) * -1, 'y')
    .format('YYYY');
};

/**
 * 기준일자(_thisDate)에서 계산된 날짜(년, 월, 일)를 가져오는 함수
 * 사용예) comm.getCalculatedDate('10d')
 * @param {*String} _thisDateStr : 기준일자(년, 월, 일)
 * @param {*String} _gapOfDate : 현재일 기준 이전 날짜, 기본값 : 1y (예) -10d - 10일전, 10m - 10개월 후, 10y - 10년후
 * @param {*String} _givenFormat : 주어진 날짜의 형식
 * @param {*String} _format : 리턴되는 날짜 형식(기본값은 ISO 표준 형식)
 */
comm.getCalculatedDate = function (
  _thisDateStr,
  _gapOfDate,
  _givenFormat,
  _format
) {
  let gapOfDate = comm.defaultGapOfDate;
  if (_gapOfDate) gapOfDate = _gapOfDate;
  if (
    _gapOfDate.indexOf('d') === -1 &&
    _gapOfDate.indexOf('m') === -1 &&
    _gapOfDate.indexOf('y') === -1
  ) {
    return;
  }
  let div = gapOfDate.substr(gapOfDate.length - 1, 1).toLowerCase();
  let gap = Number(gapOfDate.substring(0, gapOfDate.length - 1));
  let thisDate = comm.moment(_thisDateStr, _givenFormat);
  // let define = _isAdd ? 1 : -1
  let format = _givenFormat.toUpperCase();
  let typeOfDate = null; // TODO : 계산되는 날짜 형식(https://momentjs.com/docs/ 페이지의 Add함수 참고)
  if (div === 'd') {
    format = _format ? _format : 'YYYY-MM-DD';
    typeOfDate = 'd';
  } else if (div === 'm') {
    format = _format ? _format : 'YYYY-MM';
    typeOfDate = 'M';
  } else if (div === 'y') {
    format = _format ? _format : 'YYYY';
    typeOfDate = 'y';
  }
  return thisDate.add(gap, typeOfDate).format(format);
};

comm.setNumberSeperator = _number => {
  if (!_number) return 0;
  if (isNaN(_number)) return null;
  return _number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * 배열과 배열을 비교해서 포함되어 있는지 확인하는 함수
 * _targetArray : 대상 배열
 * _array : 원본 배열
 */
comm.removeDuplicatedArray = (_targetArray, _array) => {
  let hasItems = false;
  let filteredArray = [];
  _.forEach(_array, _item => {
    hasItems = false;
    _.forEach(_targetArray, __item => {
      if (JSON.stringify(_item) === JSON.stringify(__item)) hasItems = true;
    });
    if (!hasItems) filteredArray.push(_item);
  });
  return filteredArray;
};

/**
 * 배열과 배열을 비교해서 포함되어 있는지 확인하는 함수 (배열안의 데이터타입이 object인 경우)
 * _targetArray : 대상 배열
 * _array : 원본 배열
 * _key : 비교할 키
 */
comm.removeDuplicatedArrayObject = (_targetArray, _array, _key) => {
  let hasItems = false;
  let filteredArray = [];
  _.forEach(_array, _item => {
    hasItems = false;
    _.forEach(_targetArray, __item => {
      if (_item[_key] === __item[_key]) {
        hasItems = true;
      }
    });
    if (!hasItems) filteredArray.push(_item);
  });
  return filteredArray;
};

comm.isDateType = _str => {
  let m = moment(_str);
  return m.isValid();
};

comm.fileDownload = (_url, _method) => {
  if (typeof _method === 'undefined') {
    _method = 'get';
  }

  let frame = null;
  let form = null;
  if (document.getElementById('__filedownload')) {
    frame = document.getElementById('__filedownload');
    form = document.getElementsByName('__filedownloadform');
  } else {
    frame = document.createElement('iframe');
    form = document.createElement('form');

    frame.id = '__filedownload';
    frame.name = '__filedownload';
    frame.style.display = 'none';
    document.body.appendChild(frame);

    frame.id = '__filedownloadform';
    form.action = _url;
    form.method = _method;
    form.target = '__filedownload';
    document.body.appendChild(form);
  }

  form.submit();
};

// // 비밀번호 확인 팝업 띄우기
// comm.openCheckPwdPopup = function () {
//   $('#openCheckPwdPopup').click();
// };

// comm.openCheckLoginPopup = function () {
//   $('#openCheckLoginPopup').click();
// };

// comm.goCheckPassword = function () {
//   $('#goCheckPwdPopup').click();
// };

// // cookie, localStorage 비움 → 로그인 화면으로 이동 (※ 로그아웃 서비스 호출 X)
// comm.goLogin = function () {
//   $('#goLogin').click();
// };

// Base64로 인코딩된 이미지, 텍스트 데이터 등을 Blob 유형으로 변환한다.
comm.base64ToBlob = function (base64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  let byteCharacters = atob(base64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);
    if (slice && slice.length > 0) {
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

  }

  let blob = new Blob(byteArrays, {
    type: contentType,
  });

  return blob;
};

comm.bytesToSize = function (bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Bytes';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

comm.getFileExtIcon = function (_fileExt) {
  let iconExt = '';
  switch (_fileExt) {
    case 'pdf':
      iconExt = 'fas fa-file-pdf';
      break;
    case 'xlsx' || 'xls':
      iconExt = 'fas fa-file-excel';
      break;
    case 'doc' || 'docx':
      iconExt = 'fas fa-file-word';
      break;
    case 'ppt' || 'pptx':
      iconExt = 'fas fa-file-powerpoint';
      break;
    case 'txt':
      iconExt = 'fas fa-file-alt';
      break;
    case 'zip':
      iconExt = 'fas fa-clone';
      break;
    default:
      iconExt = 'fas fa-file-alt';
      break;
  }
  return iconExt;
};

comm.getAccept = function (_fileExt) {
  let accept = 'application';
  if (_fileExt.indexOf('xls') >= 0) accept += '/vnd.ms-excel';
  else if (_fileExt.indexOf('doc') >= 0) accept += '/msword';
  else if (_fileExt.indexOf('ppt') >= 0) accept += '/vnd.ms-powerpoint';
  else if (_fileExt.indexOf('pdf') >= 0) accept += '/pdf';
  else accept += '/octet-stream';

  return accept;
};

comm.isStringEmpty = function (_str) {
  if (_str === undefined) return true;
  if (_str === null) return true;
  if (_str.replace(/(\s*)/g, '') === '') return true;
  return false;
};

comm.toUndrFromCamelCase = function (_str) {
  return _str
    .replace(/(.)([A-Z][a-z]+)/g, '$1_$2')
    .replace(/([a-z0-9])([A-Z])/g, '$1_$2')
    .toLowerCase();
};

comm.telephoneMask = function (val) {
  if (val) {
    if (val.length <= 11) {
      return '##-###-####'
    } else if (val.length === 12) {
      return '###-###-####'
    } else {
      return '###-####-####'
    }
  } else {
    return ''
  }
}

comm.generateRandom = function (min, max) {
  let ranNum = Math.floor(Math.random() * (max - min + 1)) + min;
  return ranNum;
}

comm.generateRandomComma = function (min, max) {
  let ranNum = Math.floor(Math.random() * (max - min + 1)) + min;
  return this.toThousandFilter | ranNum;
}

comm.getCookie = function (cookieName) {
  return Cookies.get(cookieName);
}

comm.setCookie = function (cookieName, contents, expireTime) {
  if (typeof expireTime !== 'undefined' || expireTime != null) {
    let exTime = Number(expireTime) / 24;
    Cookies.set(cookieName, contents, {
      expires: exTime
    });
  } else {
    Cookies.set(cookieName, contents);
  }
}

/**
 * 재귀함수로 promise 처리하는 함수
 * @param {Promise 함수와 옵션값을 가지고 있는 Object 배열} _promises
 * @param {배열의 index} _currIndex
 * _promises 내부 인자 값 설명
 * @param {Promise 함수} _promises[_currIndex].func
 * @param {Promise 함수의 parameter} _promises[_currIndex].param
 * @param {예외 처리용 메시지} _promises[_currIndex].exceptionMessage
 * @param {성공 처리용 메시지} _promises[_currIndex].successMessage
 */
comm.recursivePromise = function (_promises, _currIndex) {
  _currIndex = typeof _currIndex === 'undefined' ? 0 : _currIndex;
  if (_currIndex >= _promises.length) return false;
  let currentPromise = _promises[_currIndex];
  let currentPromiseFunc = currentPromise.func;
  let param = currentPromise.param ? currentPromise.param : null;

  let alertMessage = {
    title: 'LBLGUIDE', // 안내
    message: null,
    type: 'warning',
  };

  if (typeof currentPromiseFunc !== 'function') return false;
  currentPromiseFunc.call(this, param).then(_resolve => {
    if (_resolve) {
      if (currentPromise.successMessage) {
        alertMessage.message = this.getLangSpecInfoMessage(currentPromise.successMessage);
        alertMessage.type = 'success';
        return window.getApp.$emit('ALERT', alertMessage);
      }
      return this.recursivePromise(_promises, ++_currIndex);
    }
    else {
      if (currentPromise.exceptionMessage) {
        alertMessage.message = this.getLangSpecInfoMessage(currentPromise.exceptionMessage);
        return window.getApp.$emit('ALERT', alertMessage);
      }
    }
  }).catch(_error => {
    if (_error) {
      if (window.getApp) {
        if (process.env.NODE_ENV !== 'production') {
          window.getApp.$emit('APP_REQUEST_ERROR', _error);
        } else {
          window.getApp.$emit('APP_REQUEST_ERROR', '서비스 처리 중 오류가 발생하였습니다.\n\r관리자에게 문의바랍니다.');
        }
      }
    } else {
      // nothing...
    }
  });
}

/**
 * 재귀함수로 promise를 호출하는 진입 함수
 * @param {Promise 배열}} _promises
 */
comm.orderedPromise = function(_promises) {
  if (_promises.length <= 0) return false;
  comm.recursivePromise(_promises, 0);
}

comm.getComboItems = function (_codeGroupCd, _attrVal1, useFlag) {
  return new Promise((_resolve, _reject) => {
    let url = '';
    if (!_attrVal1) url = window.getApp.$format(selectConfig.sys.code.mst.list.url, _codeGroupCd);
    else url = window.getApp.$format(selectConfig.sys.code.mst.list.attr.url, _codeGroupCd, _attrVal1);
    window.getApp.$http.url = url;
    window.getApp.$http.type = 'GET';
    window.getApp.$http.request(
      _result => {
        if (!useFlag) {
          _resolve(_result.data);
        } else {
          _resolve(_.filter(_result.data, { useFlag: useFlag }));
        }
      },
      _error => {
        _reject(_error);
      }
    );
  });
}

comm.getMultiComboItems = function (_codeGroupCdArr) {
  return new Promise((_resolve, _reject) => {
    window.getApp.$http.url = selectConfig.sys.code.mst.list.multi.url;
    window.getApp.$http.type = 'GET';
    window.getApp.$http.param = {
      codeGroupCds: _codeGroupCdArr
    };
    window.getApp.$http.request(
      _result => {
        let returnData = {};
        _.forEach(_codeGroupCdArr, group => {
          returnData[group] = _.filter(_result.data, { codeGrpCd: group })
        })
        _resolve(returnData);
      },
      _error => {
        _reject(_error);
      }
    );
  });
}

/**
 * 10진수 to 16진수
 * @param {16진수} _hex
 */
comm.hexToDecimal = function(_hex) {
  return parseInt(_hex, 16);
}

/**
 * 16진수 to 10진수
 * @param {10진수} _decimal
 */
comm.decimalToHex = function(_decimal) {
  return _decimal.toString(16);
}

comm.getTextColorByBackgroundColor = function (_backgroundColor) {
  if (!_backgroundColor || _backgroundColor.length < 6) return;
  _backgroundColor = _backgroundColor.indexOf('#') === 0 ? _backgroundColor.split.join('') : _backgroundColor;

  let rgb = new Array(3);

  if (_backgroundColor.indexOf('rgb(') >= 0) rgb = comm.getRgbArray(_backgroundColor);
  else {
    rgb.push(_backgroundColor.substr(0, 2));
    rgb.push(_backgroundColor.substr(2, 2));
    rgb.push(_backgroundColor.substr(4, 2));
  }

  let baseValue = Math.round(((parseInt(rgb[0], 10) * 299) + (parseInt(rgb[1], 10) * 587) + (parseInt(rgb[2], 10) * 114)) / 1000);
  return baseValue > 125 ? '#212529' : '#FCFCFC';
}

comm.getRgbArray = function(_backgroundColor) {
  return _backgroundColor.replace(/[^\d,]/g, '').split(',');
}

comm.getLang = function() {
  return Cookies.get('language') || 'kr';
}

comm.getLangSpecInfoLabel = function(code, formatVals) {
  let returnText = '';
  if ($store.getters.langInfo.label) {
    returnText = $store.getters.langInfo.label[code];

    if (returnText && formatVals) {
      returnText = $format(returnText, formatVals);
    }
  }
  return returnText ? returnText : code;
}

comm.getLangSpecInfoMessage = function(code, formatVals) {
  let returnText = '';
  if ($store.getters.langInfo.message) {
    returnText = $store.getters.langInfo.message[code];

    if (returnText && formatVals) {
      returnText = $format(returnText, formatVals);
    }
  }
  return returnText ? returnText : code;
}


comm.stringToBoolean = function(string){
  switch(string.toLowerCase().trim()){
      case "true": case "yes": case "1": return true;
      case "false": case "no": case "0": case null: return false;
      default: return Boolean(string);
  }
}

comm.getRequiredData = function(columns){
  let required = [];
  _.forEach(columns, column => {
    if (column.required === true) {
      required.push(column)
    }
    if (column.child && column.child.length > 0) {
      let _required = this.getRequiredData(column.child);
      if (_required && _required.length > 0) {
        required = _.concat(required, _required);
      }
    }
  })
  return required;
}

comm.validTable = function(columns, data, messageTarget, emptyFlag){
  let required = this.getRequiredData(columns); // _.filter(columns, { required: true });
  if (required && required.length > 0) {
    if (!data || data.length === 0) {
      if (!emptyFlag) {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: !messageTarget ? '데이터가 없습니다.' : `[${messageTarget}] ${this.getLanguage('데이터가 없습니다.')}`,
          type: 'warning', // success / info / warning / error
        });
        return false;
      } else {
        return true;
      }
    }
    let checkItemType = _.map(required, 'type');
    let checkItem = _.map(required, 'name');
    let checkItemText = _.map(required, 'label');
    let isConti = true;
    _.forEach(data, item => {
      let idx = 0;
      _.forEach(checkItem, check => {
        if (!item[check]) {
          isConti = false;
          return false;
        } else if (Array.isArray(item[check]) && item[check].length === 0) {
          isConti = false;
          return false;
        } else if (checkItemType[idx] === 'proxy' && item[check] === '보기') {
          isConti = false;
          return false;
        }
        idx++
      })
    });
  
    if (!isConti) {
      let citexts = _.split(checkItemText, ',');
      let citextslang = [];
      _.forEach(citexts, _item => {
        citextslang.push(this.getLanguage(_item));
      })
      window.getApp.$emit('ALERT', {
        title: '안내', 
        message: this.getLanguage('필수 입력값을 입력해 주세요.') + '\n\r[' + citextslang.join(', ') + ']',
        type: 'warning', // success / info / warning / error
      });
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}

comm.getStepItems = function (_stepperGrpCd) {
  return new Promise((_resolve, _reject) => {
    window.getApp.$http.url = window.getApp.$format(selectConfig.sys.stepper.mst.list.url, _stepperGrpCd);
    window.getApp.$http.type = 'GET';
    window.getApp.$http.request(
      _result => {
        _resolve(_result.data);
      },
      _error => {
        _reject(_error);
      }
    );
  });
}

comm.convertHtml = function (text) {
  return text ? text.replace(/&gt;/g, '>').replace(/&lt;/g, '<') : '';
}

comm.previewImage = function (file) {
  return new Promise((_resolve, _reject) => {
    if (!file) return;
    if (file.sysAttachFileId) {
      window.getApp.$http.url = selectConfig.com.upload.preview.url;
      window.getApp.$http.type = 'GET';
      window.getApp.$http.param = {
        sysAttachFileId: file.sysAttachFileId,
      };
      window.getApp.$http.request(
        _result => {
          _resolve('data:' + file.contentType + ';base64,' + _result.data)
        },
        _error => {
          _reject(_error);
        }
      );
    }
  });
}

comm.previewImageParamTask = function (taskKey, taskClassCd) {
  return new Promise((_resolve, _reject) => {
    if (!taskKey || !taskClassCd) return;
    window.getApp.$http.url = selectConfig.com.upload.previewParamTask.url;
    window.getApp.$http.type = 'GET';
    window.getApp.$http.param = {
      taskKey: taskKey,
      taskClassCd: taskClassCd,
    };
    window.getApp.$http.request(
      _result => {
        _resolve(_result.data);
      },
      _error => {
        _reject(_error);
      }
    );
  });
}

comm.uploderSetting = function (taskClassCd) {
  return new Promise((_resolve, _reject) => {
    if (!taskClassCd) return;
    window.getApp.$http.url = window.getApp.$format(selectConfig.sys.attach.get.url, taskClassCd);
    window.getApp.$http.type = 'GET';
    window.getApp.$http.request(
      _result => {
        _resolve(_result.data);
      },
      _error => {
        _reject(_error);
      }
    );
  });
}

comm.getHeaders = function (sheet, opts) {
  let header = 0;
  let hdr=[];
  let o = opts || {};
  if (sheet == null || sheet["!ref"] == null) return [];
  let range = o.range !== undefined ? o.range : sheet["!ref"];
  let r;
  if (o.header === 1) header = 1;
  else if (o.header === "A") header = 2;
  else if (Array.isArray(o.header)) header = 3;
  switch (typeof range) {
      case 'string':
          r = this.safe_decode_range(range);
          break;
      case 'number':
          r = this.safe_decode_range(sheet["!ref"]);
          r.s.r = range;
          break;
      default:
          r = range;
  }
  let rr = XLSX.utils.encode_row(r.s.r);
  let cols = new Array(r.e.c - r.s.c + 1);
  for (let C = r.s.c; C <= r.e.c; ++C) {
      cols[C] = XLSX.utils.encode_col(C);
      let val = sheet[cols[C] + rr];
      switch (header) {
          case 1:
              hdr.push(C);
              break;
          case 2:
              hdr.push(cols[C]);
              break;
          case 3:
              hdr.push(o.header[C - r.s.c]);
              break;
          default:
              if (val === undefined) continue;
              hdr.push(XLSX.utils.format_cell(val));
      }
  }
  return hdr;
}



comm.safe_decode_range = function (range) {
  let o = {s:{c:0,r:0},e:{c:0,r:0}};
  let idx = 0, i = 0, cc = 0;
  let len = range.length;
  for(idx = 0; i < len; ++i) {
      if((cc=range.charCodeAt(i)-64) < 1 || cc > 26) break;
      idx = 26*idx + cc;
  }
  o.s.c = --idx;

  for(idx = 0; i < len; ++i) {
      if((cc=range.charCodeAt(i)-48) < 0 || cc > 9) break;
      idx = 10*idx + cc;
  }
  o.s.r = --idx;

  if(i === len || range.charCodeAt(++i) === 58) { o.e.c=o.s.c; o.e.r=o.s.r; return o; }

  for(idx = 0; i != len; ++i) {
      if((cc=range.charCodeAt(i)-64) < 1 || cc > 26) break;
      idx = 26*idx + cc;
  }
  o.e.c = --idx;

  for(idx = 0; i != len; ++i) {
      if((cc=range.charCodeAt(i)-48) < 0 || cc > 9) break;
      idx = 10*idx + cc;
  }
  o.e.r = --idx;
  return o;
}

comm.convertEnter = function (str) {
  str = String(str);
  if (str === 'null' || str === 'undefined') { 
    str = '';
  }
  return str ? str.replaceAll(/(?:\\r\\n|\\n\\r|\\r|\\n)/gm, '<br />') : '';
  // return str ? str.replace(/ /g, '&nbsp;').replace(/(?:\r\n|\r|\n)/g, '<br />') : '';
}

comm.ibmTagItems = function (row, colorItems) {
  let returnData = [];
  let splitId = _.split(row['sopImprovementIds'], ',');
  let splitStep = _.split(row['ibmStepNames'], ',');
  let splitClass = _.split(row['ibmClassCds'], ',');
  let splitTooltip = _.split(row['ibmTooltip'], '│');

  if (splitId.length === splitStep.length) {
    let i = 0;
    let isClass = (splitClass && splitClass.length > 0)
    _.forEach(splitId, item => {
      // let color = _.find(colorItems, { stepperMstNm: splitStep[i] });
      let titleData = _.split(_.split(splitTooltip[i], '|')[0], ':')[1]
      if (titleData && titleData.length > 5) {
        titleData = titleData.substring(0, 5) + '...'
      }
      let title = `${titleData} [${splitStep[i]}]`;
      if (isClass && splitClass[i] === 'IC00000005') {
        title = `${titleData} ${this.getLanguage('[즉시조치]')}` 
      }
      returnData.push({
        title: title,
        color: isClass && splitClass[i] === 'IC00000005' ? 'light-blue' : 'red-6', // color ? color.colorClass : '',
        sopImprovementId: item,
        ibmClassCd: isClass ? splitClass[i] : '',
        ibmTooltip: splitTooltip[i],
      });
      i++;
    });
  }
  return returnData;
}

comm.inspectIbmTagItems = function (row, otherKey) {
  let returnData = [];
  let splitId = _.split(row['sopImprovementIds'], ',');
  let splitStep = _.split(row['ibmStepNames'], ',');
  let splitClass = _.split(row['ibmClassCds'], ',');
  let splitTooltip = _.split(row['ibmTooltip'], '│');
  let splitTableKey = _.split(row['tableKeys'], ',');
  if (splitId.length === splitStep.length) {
    let i = -1;
    let isClass = (splitClass && splitClass.length > 0)
    _.forEach(splitId, item => {
      i++;
      
      if (otherKey && otherKey.length > 0) {
        if (splitTableKey[i] !== row[otherKey[1]]) return;
      }
      // let color = _.find(colorItems, { stepperMstNm: splitStep[i] });
      let titleData = _.split(_.split(splitTooltip[i], '|')[0], ':')[1]
      if (titleData && titleData.length > 5) {
        titleData = titleData.substring(0, 5) + '...'
      }
      let title = `${titleData} [${splitStep[i]}]`;
      if (isClass && splitClass[i] === 'IC00000005') {
        title = `${titleData} ${this.getLanguage('[즉시조치]')}` 
      }
      returnData.push({
        title: title,
        color: isClass && splitClass[i] === 'IC00000005' ? 'light-blue' : 'red-6', // color ? color.colorClass : '',
        sopImprovementId: item,
        ibmClassCd: isClass ? splitClass[i] : '',
        ibmTooltip: splitTooltip[i],
      });
    });
  }
  return returnData;
}

comm.fmImprTagItems = function (row) {
  let returnData = [];
  let splitId = _.split(row['ram4mAssessImprIds'], ',');
  let splitStep = _.split(row['stepFlagNames'], ',');
  let splitTooltip = _.split(row['ibmFmTooltip'], '│');

  if (splitId.length === splitStep.length) {
    let i = 0;
    _.forEach(splitId, item => {
      let titleData = _.split(_.split(splitTooltip[i], '|')[0], ':')[1]
      if (titleData && titleData.length > 5) {
        titleData = titleData.substring(0, 5) + '...'
      }
      let title = `${titleData} [${splitStep[i]}]`;
      returnData.push({
        title: title,
        color: 'red-6',
        ram4mAssessImprId: item,
        ibmFmTooltip: splitTooltip[i],
      });
      i++;
    });
  }
  return returnData;
}

comm.getLangLabelJsonApi = function () {
  return `${Vue.prototype.$langJsonApi}${this.getLang()}_label.json`;
}

comm.getLangMessageJsonApi = function () {
  return `${Vue.prototype.$langJsonApi}${this.getLang()}_message.json`;
}

comm.getLanguageJsonApi = function () {
  return `${Vue.prototype.$langJsonApi}${this.getLang()}_language.json`;
}

comm.getLangLabel = function (lblCd, format, mstCd) {
  if (!$store.state.lang 
    || !$store.state.lang.lbls 
    || $store.state.lang.lbls.length === 0) return lblCd;
  let labelList = $store.state.lang.lbls;
  if (mstCd) {
    labelList = _.filter($store.state.lang.lbls, { mstCd: mstCd })
  }
  let data = _.find(labelList, { lblCd: lblCd })
  let text = data ? data.lblConversionVal : '';
  if (text && format) {
    text = $format(text, format);
  }
  return text ? text : lblCd;
}

comm.getLangMessage = function (msgCd, format, mstCd) {
  if (!$store.state.lang 
    || !$store.state.lang.msgs 
    || $store.state.lang.msgs.length === 0) return msgCd;
  let labelList = $store.state.lang.msgs;
  if (mstCd) {
    labelList = _.filter($store.state.lang.msgs, { mstCd: mstCd })
  }
  let data = _.find(labelList, { msgCd: msgCd })
  let text = data ? data.msgConversionVal : '';
  if (text && format) {
    text = $format(text, format);
  }
  return text ? text : msgCd;
}

comm.getLanguage = function (languageKey, format) {
  if (!$store.state.lang 
    || !$store.state.lang.langs 
    || $store.state.lang.langs.length === 0) return languageKey;
  let labelList = $store.state.lang.langs;
  let data = _.find(labelList, { languageKey: languageKey })
  let text = data ? data.conversionLanguage : '';
  if (text && format) {
    text = $format(text, format);
  }
  return text ? text : languageKey;
}

export default comm;
