/**
 * 줄임말 -> 기본말 언어팩 (메뉴용)
 */

// 한국어
export const krAbbreviationMap = [
  {
    conversionLanguage: 'Tool Box Meeting',
    languageKey: 'TBM',
  },
  {
    conversionLanguage: 'Job Safety Analysis-Standard Operation Procedure',
    languageKey: 'JSA-SOP',
  },
];
// 영어
export const enAbbreviationMap = [
  {
    conversionLanguage: 'Musculoskeletal Symptom Questionnaire',
    languageKey: '근골격계 증상설문',
  },
  {
    conversionLanguage: 'Tool Box Meeting',
    languageKey: 'TBM',
  },
  {
    conversionLanguage: 'Job Safety Analysis-Standard Operation Procedure',
    languageKey: 'JSA-SOP',
  },
];
// 일본어
export const jaAbbreviationMap = [
  {
    conversionLanguage: 'Tool Box Meeting',
    languageKey: 'TBM',
  },
  {
    conversionLanguage: 'Job Safety Analysis-Standard Operation Procedure',
    languageKey: 'JSA-SOP',
  },
];
// 중국어
export const zhcnAbbreviationMap = [
  {
    conversionLanguage: 'Tool Box Meeting',
    languageKey: 'TBM',
  },
  {
    conversionLanguage: 'Job Safety Analysis-Standard Operation Procedure',
    languageKey: 'JSA-SOP',
  },
];
export const zhtwAbbreviationMap = [
  {
    conversionLanguage: 'Tool Box Meeting',
    languageKey: 'TBM',
  },
  {
    conversionLanguage: 'Job Safety Analysis-Standard Operation Procedure',
    languageKey: 'JSA-SOP',
  },
];

export default krAbbreviationMap