<!--
목적 : 확장검색 기능을 지원하는 컴포넌트
Detail :
 *
examples:
 *  presentation
-->
<template>
  <div ref="stepper">
    <q-list ref="stepper-header" class="cursor-pointer" @click.stop="openStep">
      <q-item>
        <q-item-section avatar>
          <q-item-label>
            <apexchart
              ref="chart"
              height="150"
              type="radialBar"
              :width="chart.chartWidth"
              :options="chart.chartOptions"
              :series="chart.series">
            </apexchart>
          </q-item-label>
        </q-item-section>
        <q-item-section class="text-h5 text-blue" style="font-weight:800">
          <q-item-label>{{$language(stepName)}}</q-item-label>
          <q-item-label caption>
            <template v-for="(item, idx) in stepItems">
              <font 
                :class="idx > currentStepIndex - 1 ? 'text-grey' : 'text-blue'"
                :style="(idx > currentStepIndex - 1 ? 'font-weight:300' : 'font-weight:700')"
                style="letter-spacing: initial;"
                :key="idx"
              >
                {{item.title}}
              </font>
              <q-icon
                v-if="idx < stepItems.length - 1"
                :key="idx + 'icon'"
                size="1.2em"
                name="chevron_right"
                :color="idx > currentStepIndex - 1 ? 'grey' : 'blue'"
              />
            </template>
            <!-- <q-icon name="chevron_right" color="grey" /> -->
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
    <el-drawer
      class="step-drawer"
      :visible.sync="visible"
      :append-to-body="true"
      :show-close="true"
      :withHeader="true"
      :title="$language(groupStepName)"
      size="100%"
      direction="btt"
      @close="close">
      <q-stepper
        animated
        flat
        bordered
        header-nav
        vertical
        alternative-labels
        ref="stepper"
        done-color="red-6"
        active-color="blue-7"
        inactive-color="blue-3"
        :keep-alive="true"
        v-model="vValue"
        @transition="transition"
      >
        <q-step
          v-for="(item, idx) in stepItems"
          :key="idx"
          :name="item.name"
          :title="$language(item.title)"
          :icon="item.icon ? item.icon : 'content_paste'"
          :style="{ 'height': height }"
          class="mobile-step"
          :disable="item.disabled"
          :done="vValue > idx"
        >
        </q-step>
      </q-stepper>
    </el-drawer>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import mixinCommon from './js/mixin-common'
export default {
  /* attributes: name, components, props, data */
  name: 'c-step-mobile',
  mixins: [mixinCommon],
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    name: {
      type: String,
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: {
      type: [Number, String],
      default: null,
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
    },
    stepperGrpCd: {
      type: String,
      default: '',
    },
    currentStepCd: {
      type: String,
      default: '',
    },
    param: {
      type: [Object, Array],
    },
    stepDisabledItems: {
      type: Array,
      default: function() {
        return [];
      },
    },
    stepperGrpName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      vValue: 1,
      height: 'auto',
      stepItems: [],
      stepperGrp: null,
      visible: false,
    };
  },
  watch: {
    // TODO : 부모의 v-model 변경을 감시(예를 들면, db로부터 데이터를 조회 한 후 값을 바인딩 할 경우)
    value() {
      this.vValue = this.$_.clone(this.value);
    },
    items() {
      this.stepItems = this.items;
    },
    currentStepCd() {
      if (this.stepItems && this.stepItems.length > 0) {
        let curIndex = this.currentStepCd ? this.$_.findIndex(this.stepItems, { name: this.currentStepCd }) : 0;
        if (curIndex > -1) {
          for (let i = 0; i <= curIndex; i++) {
            this.$set(this.stepItems[i], 'disabled', false)
          }
        }
      }
    },
    stepDisabledItems: {
      handler: function () {
        if (this.stepItems && this.stepItems.length > 0 && this.stepDisabledItems && this.stepDisabledItems.length > 0) {
          for (let i = 0; i < this.stepItems.length; i++) {
            if (this.$_.indexOf(this.stepDisabledItems, this.stepItems[i].name) > -1) {
              this.$set(this.stepItems[i], 'disabled', true)
            }
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    currentStepIndex() {
      return this.stepItems && this.stepItems.length > 0 ?
        this.$_.findIndex(this.stepItems, { name: this.currentStepCd }) + 1 : 0;
    },
    stepName() {
      return this.stepItems && this.stepItems.length > 0 && this.vValue ?
        this.$_.find(this.stepItems, { name: this.vValue }).title : '';
    },
    series() {
      return [this.$_.toInteger((this.currentStepIndex / this.stepItems.length) * 100)]
    },
    chart() {
      return {
        series: this.series,
        chartOptions: {
          chart: {
            height: 150,
            type: 'radialBar',
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 135,
              dataLabels: {
                name: {
                  fontSize: '18px',
                  color: undefined,
                  offsetY: 5
                },
                value: {
                  fontSize: '0px',
                  color: undefined,
                  formatter: function () {
                    return "";
                  }
                }
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              shadeIntensity: 0.15,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 65, 91]
            },
          },
          stroke: {
            dashArray: 4
          },
          labels: [`${this.currentStepIndex} / ${this.stepItems.length}`],
        },
      }
    },
    groupStepName() {
      return this.stepperGrpName ? 
        this.stepperGrpName : this.stepperGrp ? 
          this.stepperGrp.stepperGrpNm : '진행상태'
    }
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
    this.init();
  },
  mounted() {
    this.setSize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  destroyed() {},
  updated() {
  },
  /* methods */
  methods: {
    init() {
      this.vValue = this.$_.clone(this.value);
      if (this.stepperGrpCd && (!this.items || this.items.length === 0)) {
        this.getItems();
        this.$comm.getStepGrp(this.stepperGrpCd).then(_result => {
          this.stepperGrp = _result
        });
      } else {
        this.stepItems = this.items;
      }
    },
    getItems() {
      this.$comm.getStepItems(this.stepperGrpCd).then(_result => {
        let curIndex = this.currentStepCd ? this.$_.findIndex(_result, { stepperMstCd: this.currentStepCd }) : 0;
        
        let checkDisabled = (this.stepDisabledItems && this.stepDisabledItems.length > 0)
        let index = 0;
        this.stepItems = [];
        this.$_.forEach(_result, item => {
          let disabled = curIndex < index
          if (checkDisabled && disabled === false) {
            disabled = (this.$_.indexOf(this.stepDisabledItems, item.stepperMstCd) > -1)
          }
          this.stepItems.push({
            name: item.stepperMstCd,
            title: item.stepperMstNm,
            icon: item.iconClass,
            disabled: disabled,
            message: this.$comm.convertHtml(item.remark),
            component: item.vuePath ? () => import(`@/pages${item.vuePath}.vue`) : null, // () => import(`${item.componentPath}`),
          });
          index++;
        })
        this.$emit('setStepItems')
      });
    },
    setSize() {
      let headerHeight = this.$refs['stepper-header'].offsetHeight;
      let tempHeight = this.$refs['stepper'].offsetHeight - (headerHeight < 10 ? 55 : headerHeight)
      if (tempHeight < 300) {
        tempHeight = 300;
      }
      this.height = String(tempHeight) + 'px';
    },
    transition(data) {
      this.setSize();
      this.stepClick(data);
    },
    emitStep(data) {
      this.$emit(data.name, data.param)
    },
    stepClick(item) {
      this.$emit('stepClick', item)
    },
    openStep() {
      this.visible = !this.visible
    },
    close() {
      this.visible = false
    },
  },
  // margin: 80px !important
};
</script>
<style lang="sass">
.q-item__section--avatar
  max-width: 30%

.step-drawer
  z-index: 6000 !important
  .el-drawer
    height: auto !important
    border-top-left-radius: 10px
    border-top-right-radius: 10px
  .el-drawer__header
    background: white
    color: black
  .mobile-step
    padding: 10px !important
    .q-stepper__label
      padding-left: 10px

.sticky-step
  position: sticky
  top: 10px
</style>