var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header-dots" }, [
    _c(
      "span",
      { attrs: { slot: "button-content" }, slot: "button-content" },
      [
        _c(
          "q-btn",
          {
            staticClass: "toplanguageshort",
            attrs: {
              flat: "",
              dense: "",
              round: "",
              color: "white",
              icon: "language",
            },
          },
          [
            _c(
              "q-menu",
              {
                attrs: {
                  anchor: "bottom left",
                  self: "top middle",
                  "auto-close": "",
                  "transition-show": "jump-down",
                  "transition-hide": "jump-up",
                },
              },
              [
                _c(
                  "q-list",
                  { staticClass: "topMenuShortLanguage" },
                  _vm._l(_vm.LangItems, function (tag) {
                    return _c(
                      "q-item",
                      {
                        directives: [{ name: "ripple", rawName: "v-ripple" }],
                        key: tag.code,
                        ref: "tag",
                        refInFor: true,
                        class: tag.class,
                        staticStyle: { "padding-left": "5px !important" },
                        attrs: { clickable: "", name: tag.codeName },
                        on: {
                          click: function ($event) {
                            return _vm.setDefaultLanguage(tag)
                          },
                        },
                      },
                      [
                        _c(
                          "q-item-section",
                          { attrs: { avatar: "" } },
                          [
                            tag.code !== "generator"
                              ? _c("country-flag", {
                                  staticClass: "lanauageFlag",
                                  attrs: { country: tag.icon },
                                })
                              : _c("q-icon", {
                                  staticStyle: {
                                    "font-size": "28px !important",
                                  },
                                  attrs: { name: tag.icon },
                                }),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { lines: "1" } }, [
                              _vm._v(_vm._s(tag.codeName)),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }